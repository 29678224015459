import { Component, OnInit } from '@angular/core';
import {DtmAnalyticsServiceService} from '../../dtm-analytics-service.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  currentUser: string;

  constructor(public dtm: DtmAnalyticsServiceService) {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  ngOnInit(): void {
  }

}
