import { Component, OnInit } from '@angular/core';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';

declare var dataLayer;

@Component({
  selector: 'app-product-offering',
  templateUrl: './product-offering.component.html',
  styleUrls: ['./product-offering.component.css']
})
export class ProductOfferingComponent implements OnInit {

  constructor(private dtm: DtmAnalyticsServiceService) { }

  ngOnInit(): void {
    this.dtm.updatePageProperties('business:corporate card dashboard:product offering');
    this.dtm.updateDataLayerForPage();
  }

}
