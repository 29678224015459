<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Corporate Card Solutions</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <mat-tab-group dynamicHeight class="col-9 w-100" (selectedTabChange)="dtm.clickEvent('informational', 'charge cards nav', 'corporate card dashboard | product offering | business credit and corporate charge cards | ' + $event.tab.textLabel + ' tab link click')">
      <mat-tab label="OVERVIEW">
        <div class="row pt-5">
          <img class="col-4 w-100 h-100" src='../../../assets/shakingHands.png' alt="Image of people shaking hands">
          <div class="col-8">
            <h4 class="SubHeading">Overview</h4>
            <div class="TextContent">
              <p>
                We offer a range of Commercial Card solutions and comprehensive expense management information that
                gives our clients the power to manage their spend and take advantage of opportunities to save money.
              </p>
              <p>
                Our solutions are designed for all operational expenses including travel, fuel, maintenance,
                entertainment and procurement. Commercial cardholders enjoy benefits such as reconciliation reports,
                travel insurance, free lost-card protection and even airport lounge access if they meet the spend
                threshold.
              </p>
              <p>
                Purchases are interest-free for up to 55 days* and the repayment date is flexible, allowing companies to
                manage their cashflow more effectively.
              </p>
              <p>
                Collect Rewards Points on all your card swipes with UCount Rewards for Business** when using a Charge or
                Credit Card.
              </p>
              <p>
                * The interest-free period is applicable from the beginning of the statement cycle and not from the
                purchase date. It is also not applicable to Garage Card and cash transactions as defined.
                ** UCount Rewards for Business Ts & Cs apply.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="bg-light">
            <h4 class="pl-4">Corporate Card Solutions</h4>
            <div class="col-9 w-100">
              <img src="../../../assets/ProductComparisonImage.png">
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
