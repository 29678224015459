<!-- dialog-box.component.html -->

<!-- <div class="dialogBox">
  <h1>
    <div class="title">
      <div class="div_centre"><img src="../../../../assets/icn-cancel.png" class="icn_cancel"></div>
      <div class="div_centre">CANCEL CARD</div>
    </div>
  </h1>
  <div [hidden]="action==='cancel card'" >
    <p class="text">You are about to change the credit limit from <b>R{{currentLimit}}</b> to
      <b>R{{newAmount}}</b></p>
      <p class="text">Do you want to proceed?</p>
    </div>
  <div [hidden]="action==='Limit Allocation'">
    <p class="text">You are about to cancel card <b>{{maskedNumber}}</b></p>
    <p class="text">Do you want to proceed?</p>
  </div>
  <div id="buttonGroup" mat-dialog-actions>
    <button id="cancel" type="button" mat-button (click)="closeDialog()" mat-flat-button color="basic">NO</button>
    <button id="submit" type="button" mat-button  (click)="doAction()">YES</button>
  </div>
</div> -->

<div class="dialogBox">
  <h1>
    <div class="title">
      <div class="div_centre"><img src="../../../../assets/icn-cancel.png" class="icn_cancel"></div>
      <div class="div_centre">CANCEL CARD</div>
    </div>
  </h1>
  <!-- <div [hidden]="action==='Card Cancellation'" >
    <p class="text">You are about to change the credit limit from <b>R{{currentLimit}}</b> to
      <b>R{{newAmount}}</b></p>
    <p class="text">Do you want to proceed?</p>
  </div>
  <div [hidden]="action==='Limit Allocation'"> -->
  <p class="text">We have received your request to cancel card number
    <span class="t5weight-med">{{maskedNumber}}</span>.</p>
  <div [hidden]="allowCancel===true" >
    <p class="text">You have an<span class="t5weight-med"> outstanding balance of {{amountNumber | currency:'ZAR':'symbol-narrow'}}<br></span>
      on the card. We are unable to proceed with your request at<br>
      this time. Please settle the amount and then try again.</p>
  </div>
  <div [hidden]="allowCancel===false" >
    <p class="text"><br>Are you sure you want to proceed?
      <br>
      <br>
      </p>
  </div>
  <p><span class="t5action-weight-reg">If your card has been lost or stolen, please phone the<br></span>
    <span class="t5action-weight-reg">fraud line on 0800 020 600 to stop the card.</span>
  </p>
  <div id="buttonGroup" mat-dialog-actions>
    <button [disabled]=false id="cancel" type="button" mat-button (click)="closeDialog()" mat-flat-button>CLOSE</button>
    <button [disabled]="review_btn" id="submit" type="button" mat-button (click)="doAction()" mat-flat-button>CANCEL CARD</button>
  </div>
</div>