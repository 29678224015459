import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procurement-card',
  templateUrl: './procurement-card.component.html',
  styleUrls: ['./procurement-card.component.css']
})
export class ProcurementCardComponent implements OnInit {
  currentUser: string;

  constructor() {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  ngOnInit(): void {
  }

}
