import { Component, AfterViewInit } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Router } from '@angular/router';
import {RestApiService} from '../_services/rest-client-service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { data } from 'jquery';
import { environment } from 'src/environments/environment';
import { environmentProd } from 'src/environments/environment.prod';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})

export class CallbackComponent implements AfterViewInit {

  public tokenResponseJSON: any;
  error = '';
  currentUser = '';

  constructor(private snackBar: MatSnackBar, private restApi: RestApiService,
              public authorizationService: AuthorizationService, public router: Router) { }

  // ngOnInit(): void {
  // }

  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit constructor for callback!');
    // setTimeout(() => {  console.log('World!'); }, 2000);
    if (!window.location.hash || window.location.hash.length === 0) {
      // console.log('ngAfterViewInit callback! no location.hash');
      const queryString = window.location.search.substring(1); // substring strips '?'
      const path = [window.location.pathname, queryString].join('#');
      window.location.assign(new URL(path, window.location.href).toString());
    } else if (new URLSearchParams(window.location.hash.substring(1)).has('code')) {
      // console.log('ngAfterViewInit callback! has code');
      this.authorizationService.completeAuthorizationRequest().then((tokenResponse) => {
        // console.log('ngAfterViewInit recieved token response: ' + data);
        return this.restApi.login('', '', '').subscribe((data: any) => {
            this.currentUser = data.username;
            if (data.status === 'No Companies') {
              this.router.navigateByUrl('/id');
            } else {
              if (data.status === 'Approved authorization') {
                sessionStorage.setItem('currentUser', this.currentUser);
                this.router.navigateByUrl('/home');
              } else {
                sessionStorage.clear();
                this.authorizationService.signOut();
                this.router.navigateByUrl('/');
                if (document.location.hostname === environment.prod_url) {
                  let redirect = environmentProd.redirect_uri;
                  let redirect_url = redirect.replace("/callback", "");
                  document.location.href = environmentProd.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environmentProd.client_id + `&TargetResource=` + redirect_url
                } else {
                  let redirect = environment.redirect_uri;
                  let redirect_url = redirect.replace("/callback", "");
                  document.location.href = environment.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environment.client_id + `&TargetResource=` + redirect_url
                }
                if (data.status === 'Rejected authorization') {
                  this.companyRejectSnackBar(data.status);
                } else {
                  this.companyOkSnackBar(data.status);
                }
              }
            }
          },
          error => {
            // console.log('ERROR>: ' + error);
            this.error = error;
          });
        // check
        // this.router.navigateByUrl('/id');
        // this.router.navigateByUrl('/home');
        // this.router.navigate(['dashboard']);
      });
    } else {
      // console.log('did not recognize callback in URL fragment or query');
      // this.router.navigate(['dashboard']);
      this.router.navigateByUrl('/');
    }
  }

  companyOkSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['black-snackbar', 'login-snackbar'],
      verticalPosition: 'top',
    });
  }

  companyRejectSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['red-snackbar', 'login-snackbar'],
      verticalPosition: 'top',
    });
  }
}
