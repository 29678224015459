import { Routes, RouterModule } from '@angular/router';
import {NgModule} from '@angular/core';

// export const AppRoutingModule = RouterModule.forRoot(routes, {useHash: false});
const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', } // Add options right here
  ),
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
