import { Injectable } from '@angular/core';
import { CompanySummary } from '../_models/companySummary';
import { Card } from '../_models/card';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  companySummary: CompanySummary;
  cards: Card[];

  constructor() { }

  setCompanySummary(companysummary: CompanySummary) {
    this.companySummary = companysummary;
  }

  getCompanySummary() {
    return this.companySummary;
  }

  setCards(cards: Card[]) {
    this.cards = cards;
  }

  getCards() {
    return this.cards;
  }
}
