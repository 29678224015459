import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {RestApiService} from '../../_services/rest-client-service';
import {Email} from '../../_models/email';
import {DtmAnalyticsServiceService} from '../../dtm-analytics-service.service';

@Component({
  selector: 'app-log-support-query',
  templateUrl: './log-support-query.component.html',
  styleUrls: ['./log-support-query.component.css']
})
export class LogSupportQueryComponent implements OnInit {
  queryForm: FormGroup;
  error = '';
  loading = false;

  constructor(private restApi: RestApiService, private readonly fb: FormBuilder, public dtm: DtmAnalyticsServiceService) {
    this.queryForm = this.fb.group({
      name: ['', Validators.required],
      number: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      queryType: ['', Validators.required],
      message: ['', ]
    });
  }

  onSubmit() {
    this.loading = true;

    const email = new Email();
    email.Name = this.queryForm.controls.name.value;
    email.Number = this.queryForm.controls.number.value;
    email.Email = this.queryForm.controls.email.value;
    email.QueryType = this.queryForm.controls.queryType.value;
    email.Message = this.queryForm.controls.message.value;

    return this.restApi.sendEmail(email).subscribe((data: {}) => {
        this.loading = false;
        this.queryForm.reset();
        Object.keys(this.queryForm.controls).forEach(key => {
          this.queryForm.controls[key].setErrors(null);
        }
        );
        this.dtm.updateDataLayerSubmit();
      },
      error => {
        this.error = error;
        this.loading = false;
      });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  ngOnInit(): void {
  }

}
