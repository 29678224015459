import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompanySummary} from '../../_models/companySummary';
import {RestApiService} from '../../_services/rest-client-service';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Card} from '../../_models/card';
import {TableUtil} from './TableUtil';
import {TableUtilsCSV} from './TableUtilsCSV';
import {MaskedCard} from '../../_models/maskedCard';
import {DataStorageService} from '../../_services/data-storage.service';
import {DtmAnalyticsServiceService} from '../../dtm-analytics-service.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  error = '';
  displayedColumns: string[] = ['id', 'cardHolder', 'cardNumber', 'mobileNumber', 'emailAddress', 'creditLimit', 'availableAmount'];
  responseData: any;
  summary: CompanySummary;
  cards: Card[];
  dataSource: any;
  formGroup: FormGroup = new FormGroup({});
  fromDate: string;
  toDate: string;
  names: string[];
  associatedCards: MaskedCard[] = [];
  cardNumbers: MaskedCard[] = [];
  loadingCardList = false;
  singleUser: Card[] = [];
  viewActive = 'ACTIVE';
  filteredCardData: Card[];

  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private restApi: RestApiService, private formBuilder: FormBuilder, private dataStore: DataStorageService,
              public dtm: DtmAnalyticsServiceService) {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      cardNumber: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadingCardList = true;
    this.summary = this.dataStore.getCompanySummary();
    if (this.summary === undefined) {
      this.getCompanySummary();
    } else {
      this.cards = this.summary.Cards;
      this.userFriendlyCardMask(this.cards);
      this.createCardNumAndNamesLists(this.cards);
      this.loadingCardList = false;
    }
  }

  getCompanySummary() {
    this.loadingCardList = true;
    return this.restApi.getCompanySummary().subscribe((data: {}) => {
        this.responseData = data;
        this.summary = this.responseData;
        this.cards = this.summary.Cards;
        this.userFriendlyCardMask(this.cards);
        this.createCardNumAndNamesLists(this.cards);
      },
      error => {
        this.error = error;
        this.loadingCardList = false;
      });
  }

  get f() {
    return this.formGroup.controls;
  }

  userFriendlyCardMask(cards: Card[]) {
    for (let card of cards) {
      let tempString = card.Account;
      card.MaskedCardNumber = tempString.replace(tempString.substr(4, 8), '******');
    }
    this.cards = cards;
  }

  filterCardView($event) {
    if ($event.checked === false) {
      this.viewActive = 'ACTIVE';
    } else {
      this.viewActive = 'INACTIVE';
    }
    this.createCardNumAndNamesLists(this.cards);
  }

  createCardNumAndNamesLists(cards: Card[]) {

    this.filteredCardData = this.cards;
    if (this.viewActive === 'INACTIVE') {
      for (let i = 0; i < this.cards.length; i++) {
        this.cards = this.cards.filter(function (obj) {
          return obj.Status === 'INACTIVE';
        });
      }
    } else {
      for (let i = 0; i < this.cards.length; i++) {
        this.cards = this.cards.filter(function (obj) {
          return obj.Status === 'ACTIVE';
        });
      }
    }

    this.names = [];
    this.cardNumbers = [];
    for (let card of cards) {
      let maskedCard = new MaskedCard();
      this.names.push(card.Name);
      maskedCard.cardHolder = card.Name;
      maskedCard.cardNo = card.Account;
      maskedCard.maskedCardNo = card.MaskedCardNumber;
      this.cardNumbers.push(maskedCard);
    }
    this.dataSource = new MatTableDataSource(this.cards);
    this.dataSource.paginator = this.paginator;
    this.loadingCardList = false;
    this.cards = this.filteredCardData;
  }

  getAssociatedCardNumbers($event) {
    this.associatedCards = [];
    if ($event.source.value !== '') {
      for (let card of this.cardNumbers) {
        if (card.cardHolder === $event.source.value) {
          this.associatedCards.push(card);
        }
      }
    }
  }

  exportTable() {
    TableUtil.exportToPdf('myTable');
  }

  exportCSVTable() {
    TableUtilsCSV.exportTableToExcel('myTable');
  }

  onFormSubmit() {

    if (this.formGroup.valid) {
      let User = new Card();
      if (this.formGroup.value.name && this.formGroup.value.cardNumber) {
        User = this.cards.find(o => o.Name === this.formGroup.value.name && o.Account === this.formGroup.value.cardNumber);
      } else if (this.formGroup.value.cardHolder) {
        User = this.cards.find(o => o.Name === this.formGroup.value.name);
      }
      this.singleUser = [];
      this.singleUser.push(User);
      this.dataSource = new MatTableDataSource(this.singleUser);
      this.dataSource.paginator = this.paginator;
    }
  }

  resetValues() {
    this.formGroup.reset();
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.controls[key].setErrors(null);
    });
    this.dataSource = new MatTableDataSource(this.cards);
    this.dataSource.paginator = this.paginator;
    if (this.table) {
      this.table.renderRows();
    }
  }
}
