<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <div class="UsersHeading">Manage Cardholders</div>
  <div class="row">
    <div class="col">
      <mat-tab-group dynamicHeight class="tabgroup w-100">
        <mat-tab>
          <app-users></app-users>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and
    Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number
    NCRCP15
  </div>
</footer>
