<div class="Help">Help</div>
<div class="Important-Numbers">Important Numbers</div>
<br>
<div class="BizDirect">BizDirect</div>
<div class="Standard-Text">Tel: 0860 001 030</div>
<br>
<div class="Corporate-Priority-D">Corporate Priority Desk</div>
<div class="Standard-Text">Tel: (Local) 0860 999 002</div>
<div class="Standard-Text">Tel: (International) +27 10 249 0440</div>
<br>
<div class="Travel-Insurance">Travel Insurance</div>
<div class="Standard-Text">Tel: +27 11 525 3101</div>
<br>
<div class="Fraud-Lost-Stole">Fraud / Lost / Stolen Cards</div>
<div class="Standard-Text">Tel: (Local) 0860 020 600</div>
<div class="Standard-Text">Tel: (International) +27 10 249 0100</div>
<br>
<div class="Alternatively-pleas">Alternatively, please contact your banking representative</div>
