<!--Issued Card List-->

<mat-card class="daCards">
  <div class="row">
    <mat-card-header class="headers">ISSUED CARDS</mat-card-header>
    <div class="filters">
      ACTIVE
      <mat-slide-toggle (change)="filterCardView($event);dtm.clickEvent('informational', 'card search', 'corporate card dashboard | card management | active inactive cards switch toggle click')">
        INACTIVE</mat-slide-toggle>
      <a (click)="changeCardSearchView();dtm.clickEvent('navigational', 'card search', 'corporate card dashboard | card management | search spyglass toggle icon click')"
      ><img class="magglass" src="../../../assets/magifyingGlass.png"></a>
    </div>

    <div [hidden]="viewCardSearch">
      <form [formGroup]="cardsFormGroup" (ngSubmit)="onCardsFormSubmit()">
        <div class="searchDropDownGroup">
          <mat-form-field class="searchDropDown">
            <mat-label>Select Card Holder</mat-label>
            <mat-select (selectionChange)="getAssociatedCardNumbers($event)" formControlName="cardHolder"
                        (click)="dtm.clickEvent('informational', 'issued card holder selector', 'corporate card dashboard | card management | card holder dropdown click')">
              <mat-option *ngFor="let cardHolder of cardSearchHolderList" [value]="cardHolder">{{ cardHolder }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="searchDropDown">
            <mat-label>Select Card Number</mat-label>
            <mat-select formControlName="cardNumber"
                        (click)="dtm.clickEvent('informational', 'issued number selector', 'corporate card dashboard | card management | card number dropdown click')">
              <mat-option *ngFor="let cardNumber of associatedCards" [value]="cardNumber.cardNo">{{ cardNumber.maskedCardNo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="searchDropDown">
            <button
              (click)="dtm.clickEvent('informational', 'issued card search', 'corporate card dashboard | card management | search issued cards display button click')"
              class="sbg-button" type="submit">DISPLAY</button>
          </div>

          <div *ngIf="singleCardView">
            <mat-card class="daSingleCard">
              <div class="frontOfCard" [hidden]="singleCard.flip">
                <div class="row">
                  <div class="walletIconBackground">
                    <img src="../../../../../assets/walletIcon.png" height="25" width="25"/>
                  </div>
                  <div class="col">
                    <div class="cardholderName">{{singleCard.Name}}</div>
                    <div class="row">
                      <div class="cardNumber">{{singleCard.MaskedCardNumber}}</div>
                      <img class="cardTypeIcon" *ngIf="singleCard.Type === 'P'" src="../../../assets/visaIcon.png">
                      <img class="cardTypeIcon" *ngIf="singleCard.Type === 'M'" src="../../../assets/masterCardIcon.png">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="label">Available Balance</div>
                    <div class="info">R {{singleCard.AvailableBalance}}</div>
                  </div>
                  <div class="col-6">
                    <div class="label">Outstanding balance</div>
                    <div class="info">R {{singleCard.LatestBalance}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="label">Credit Limit</div>
                    <div class="info">R {{singleCard.CreditLimit}}</div>
                  </div>
                  <div class="buttonGroup">
                    <!-- <button class="cancelCard" mat-button (click)="openDialog('Card Cancellation',singleCard);
dtm.clickEvent('informational', 'cancel card', 'corporate card dashboard | card management | cancel searched issued card cancel card button click')"
                    >Cancel Card</button> -->
                    <button class="viewBack" mat-button (click)="singleCard.flip = !singleCard.flip"
                            (click)="dtm.clickEvent('informational', 'card flip', 'corporate card dashboard | card management | flip searched issue card view back button click')"
                    >View Back</button>
                  </div>
                </div>
              </div>
              <!--Back-->
              <div class="backOfCard" [hidden]="!singleCard.flip">
                <div class="row">
                  <div class="col">
                    <div class="label">Email</div>
                    <div class="info">{{singleCard.Email}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="label">Contact Number</div>
                    <div class="info">{{singleCard.ContactNumber}}</div>
                  </div>
                  <div class="col-6">
                    <div class="label">Mobile Number</div>
                    <div class="info">{{singleCard.MobileNumber}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="label">Travel Notification</div>
                    <div class="info">{{singleCard.TravelNotification}}</div>
                  </div>
                  <div class="col-6">
                    <div class="label">Card Expiry date</div>
                    <div class="info">{{singleCard.CardExpiry}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="label">Travel Date range</div>
                    <div class="row info">
                      <div>{{singleCard.TravelDateStart}} -</div>
                      <div>{{singleCard.TravelDateEnd}}</div>
                    </div>
                  </div>
                  <div class="buttonGroup">
                    <button class="viewFront" mat-button (click)="singleCard.flip = !singleCard.flip;
dtm.clickEvent('informational', 'card flip', 'corporate card dashboard | card management | flip searched issued card view front button click')"
                    >View Front</button>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <hr class="greyHardRule">
        </div>
      </form>
    </div>

  </div>
  <mat-card-content>
    <div class="noCards" *ngIf="!cardData">No Cards to display</div>
    <div *ngIf="cardData" class="row cardBox">
      <mat-card [hidden]="card.Status!==viewActive" *ngFor="let card of cardData.card" class=" daCard">
        <!--Front-->
        <div class="frontOfCard" [hidden]="card.flip">
          <div class="row">
            <div class="walletIconBackground">
              <img src="../../../../../assets/walletIcon.png" height="25" width="25"/>
            </div>
            <div class="col">
              <div class="cardholderName">{{card.Name}}</div>
              <div class="row">
                <div class="cardNumber">{{card.MaskedCardNumber}}</div>
                <img class="cardTypeIcon" *ngIf="card.Type === 'P'" src="../../../assets/visaIcon.png">
                <img class="cardTypeIcon" *ngIf="card.Type === 'M'" src="../../../assets/masterCardIcon.png">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="label">Available Balance</div>
              <div class="info">R {{card.AvailableBalance}}</div>
            </div>
            <div class="col-6">
              <div class="label">Outstanding balance</div>
              <div class="info">R {{card.LatestBalance}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="label">Credit Limit</div>
              <div class="info">R {{card.CreditLimit}}</div>
            </div>
            <div class="buttonGroup">
              <!-- <button class="cancelCard" mat-button (click)="openDialog('Card Cancellation',card);
dtm.clickEvent('informational', 'cancel card', 'corporate card dashboard | card management | cancel issued card cancel card button click')"
              >Cancel Card</button> -->
              <button class="viewBack" mat-button (click)="card.flip = !card.flip"
                      (click)="dtm.clickEvent('informational', 'card flip', 'corporate card dashboard | card management | flip issue card view back button click')"
              >View Back</button>
            </div>
          </div>
        </div>
        <!--Back-->
        <div class="backOfCard" [hidden]="!card.flip">
          <div class="row">
            <div class="col">
              <div class="label">Email</div>
              <div class="info">{{card.Email}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="label">Contact Number</div>
              <div class="info">{{card.ContactNumber}}</div>
            </div>
            <div class="col-6">
              <div class="label">Mobile Number</div>
              <div class="info">{{card.MobileNumber}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="label">Travel Notification</div>
              <div class="info">{{card.TravelNotification}}</div>
            </div>
            <div class="col-6">
              <div class="label">Card Expiry date</div>
              <div class="info">{{card.CardExpiry}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="label">Travel Date range</div>
              <div class="row info">
                <div>{{card.TravelDateStart}} -</div>
                <div>{{card.TravelDateEnd}}</div>
              </div>
            </div>
            <div class="buttonGroup">
              <button class="viewFront" mat-button (click)="card.flip = !card.flip;
dtm.clickEvent('informational', 'card flip', 'corporate card dashboard | card management | flip issued card view front button click')"
              >View Front</button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>

<!-- <mat-card class="daCardList">

  <mat-card-header class="limitTransfersHeading">
    <div class="expand-line">
      <span class="alignLeft">Card Holder Limit Transfers</span>
      <span class="alignRight">
        <table summary="align">
          <tr>
            <td class="smallHeaderPadding">Company credit limit</td>
            <td class="smallHeaderPadding">Available credit limit</td>
            <td class="smallHeaderPadding">Unallocated credit limit</td>
          </tr>
          <tr>
            <td class="headerPadding">R {{creditLimit | number:'.2-2'}}</td>
            <td class="headerPadding" [ngClass]="{positive: inTheGreen(creditAllocated),
              negative: !inTheGreen(creditAllocated)}">R {{creditAllocated | number:'.2-2'}}</td>
            <td class="headerPadding" [ngClass]="{positive: inTheGreen(changeCreditAllocated),
              negative: !inTheGreen(changeCreditAllocated)}">R {{changeCreditAllocated | number:'.2-2'}}</td>
          </tr>
        </table>
      </span>
      <div style="clear:both;"></div>
    </div>
  </mat-card-header>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="my-table mat-elevation-z8">

      <ng-container matColumnDef="cardHolder">
        <th mat-header-cell *matHeaderCellDef> CARDHOLDER</th>
        <td mat-cell *matCellDef="let element"> {{element.Name}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="cardNumber">
        <th mat-header-cell *matHeaderCellDef> CARD NUMBER</th>
        <td mat-cell *matCellDef="let element"> {{element.MaskedCardNumber}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="spend">
        <th mat-header-cell *matHeaderCellDef> SPEND</th>
        <td mat-cell *matCellDef="let element" class="cellAlignRight">R {{element.LatestBalance | number:'.2-2'}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="limit">
        <th mat-header-cell *matHeaderCellDef> Limit</th>
        <td mat-cell *matCellDef="let element" class="cellAlignRight">R {{element.CreditLimit | number:'.2-2'}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="available">
        <th mat-header-cell *matHeaderCellDef> AVAILABLE</th>
        <td mat-cell *matCellDef="let element" class="cellAlignRight" [ngClass]="{positive: inTheGreen(element.AvailableBalance),
          negative: !inTheGreen(element.AvailableBalance)}">R {{element.AvailableBalance | number:'.2-2'}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="allocatedTo">
        <th mat-header-cell *matHeaderCellDef> ALLOCATE FUNDS TO</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field>
            <mat-select [(value)]="element.allocatedTo"
                        #detail="ngModel"
                        [(ngModel)]="element.allocatedTo" minlength={{minLenth}} maxlength={{maxLenth}}
                        (selectionChange)="checkCreditAllocated(element)"
                        (click)="dtm.clickEvent('informational', 'issued card holder update selector', 'corporate card dashboard | card management | card holder update dropdown click')">
              <mat-option *ngFor="let allocatedTo of cardHolderList" [value]="allocatedTo.cardNo" >{{ allocatedTo.maskedCardNo }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="detail.touched && detail.errors?.minlength">
              You cannot allocate to same account.
            </mat-error>
          </mat-form-field>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="allocatedAmount">
        <th mat-header-cell *matHeaderCellDef> ALLOCATE AMOUNT</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field>
            <span matPrefix>R &nbsp;</span>
            <input matInput type="number"
                   [(ngModel)]="element.allocatedAmount"
                   (input)="allocatedAmount = $event.target.value;
                   element.AvailableBalance = element.CreditLimit + (result = element.cardLimitAmount === undefined ? 0 : element.cardLimitAmount) - element.LatestBalance -
                        (result = element.allocatedAmount === undefined ? 0 : element.allocatedAmount);updateAvailable(element)"
                   [value]="element.allocatedAmount" placeholder=" ">
          </mat-form-field>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="cardLimitAmount">
        <th mat-header-cell *matHeaderCellDef> CARD LIMIT INCR. / DEC.</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field>
            <span matPrefix>R &nbsp;</span>
            <input matInput type="number"
                   [(ngModel)]="element.cardLimitAmount"
                   (input)="cardLimitAmount = $event.target.value;changeCreditAllocatedUpdate(element);
                   element.AvailableBalance = element.CreditLimit + (result = element.cardLimitAmount === undefined ? 0 : element.cardLimitAmount) - element.LatestBalance -
                        (result = element.allocatedAmount === undefined ? 0 : element.allocatedAmount)"
                   [value]="element.cardLimitAmount" placeholder=" ">
          </mat-form-field>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element" class="action-link">
          <button class="updateButton" mat-button
          [disabled]="(!element.allocatedTo||!element.allocatedAmount||0>element.AvailableBalance)&&
          (!element.cardLimitAmount||0>element.AvailableBalance)"
          (click)="openDialog('Limit Allocation', element);
                  dtm.clickEvent('informational', 'issued card holder limit update', 'corporate card dashboard | card management | card holder limit update button click');
                  emptyFields(element)"
          >Update</button>
        </td>
        <td hidden mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="maskedCard">
        <th hidden="true" mat-header-cell *matHeaderCellDef> maskedNumber</th>
        <td hidden="true" mat-cell *matCellDef="let element"  [value]="element.maskedNumber"> {{element.maskedCardNo}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>

</mat-card> -->


