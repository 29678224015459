import { Component } from '@angular/core';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.css']
})
export class StatementsComponent {
  currentUser: string;

  constructor(private dtm: DtmAnalyticsServiceService) {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  ngOnInit() {
    this.dtm.updatePageProperties('business:corporate card dashboard:transactions');
    this.dtm.updateDataLayerForPage();
  }
}
