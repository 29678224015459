<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Corporate Card Solutions</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <mat-tab-group dynamicHeight class="col-9 w-100" (selectedTabChange)="dtm.clickEvent('informational', 'charge cards nav', 'corporate card dashboard | product offering | business credit and corporate charge cards | ' + $event.tab.textLabel + ' tab link click')">
      <mat-tab label="BUSINESS CREDIT CARDS">
        <div class="row pt-5">
          <img class="col-4 w-100 h-100" src='../../../assets/climbingOutCar.png'
               alt="Image of man climbing out of a car">
          <div class="col-4">
            <h4 class="SubHeading">Business Credit Cards</h4>
            <div class="TextContent">
              <p>
                In our commitment to excellent service we’ve designed the Buisness Credit Card to minimise the
                administrative burden and risks associated with authorising cash advances and carrying cash for travel
                and entertainment. This flexible and convenient payment solution from our Commercial Card range covers
                expenses such as flight tickets, hotels, car hire and meals.
              </p>
              <p>
                From full expense tracking and travel rewards to access to interest-free, short term credit, this card
                has it all.
              </p>
            </div>
          </div>
          <div class="col-4">
            <img class="w-75" src="../../../assets/businessCard.png" alt="Image of travel lodge cards">
            <button mat-raised-button color="primary" hidden>APPLY NOW</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
