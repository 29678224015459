import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { interval } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { environmentProd } from 'src/environments/environment.prod';
import { RestApiService } from 'src/app/_services/rest-client-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'corporate-card-frontend';
  data: number = 0;
  error = '';
  dateTime: Date = new Date();

  constructor(public authorizationService: AuthorizationService, private router: Router, private restApi: RestApiService,
    private renderer: Renderer2) {
  }

  ngAfterViewChecked() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  ngOnInit(): void {
    // Current code is done so a browser refresh does not prolong the token refresh to the point of getting an expired token
    // set time in local storage to 4 minutes in the future
    this.dateTime = new Date();
    this.dateTime.setMinutes(this.dateTime.getMinutes() + 4);
    let newTime = this.dateTime.getTime();
    if (!sessionStorage.getItem('refresh-time')) {
      sessionStorage.setItem('refresh-time', newTime.toString());
    }
    // execute every 10 seconds
    const obs$ = interval(1000 * 10);
    obs$.subscribe((d) => {
      let currentTime = new Date().getTime();
      if (currentTime > Number(sessionStorage.getItem('refresh-time'))) {
        // set new time in the future
        this.dateTime = new Date();
        this.dateTime.setMinutes(this.dateTime.getMinutes() + 4);
        let newTime = this.dateTime.getTime();
        if (sessionStorage.getItem('refresh-time')) {
          sessionStorage.removeItem('refresh-time');
        }
        sessionStorage.setItem('refresh-time', newTime.toString());
        // check if logged in
        // console.log("newTime:", newTime)
        if (!sessionStorage.getItem('logged-in')) {
          sessionStorage.setItem('logged-in', 'no');
        }
        if (sessionStorage.getItem('logged-in') == 'no') {
          this.data = 0;
        } else {
          // if token refreshed more than 9 times log out
          if (this.data > 10) {
            sessionStorage.clear();
            this.authorizationService.signOut();
            this.router.navigateByUrl('/');

            if (document.location.hostname === environment.prod_url) {
              let redirect = environmentProd.redirect_uri;
              let redirect_url = redirect.replace("/callback", "");
              document.location.href = environmentProd.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environmentProd.client_id + `&TargetResource=` + redirect_url
            } else {
              let redirect = environment.redirect_uri;
              let redirect_url = redirect.replace("/callback", "");
              document.location.href = environment.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environment.client_id + `&TargetResource=` + redirect_url
            }
          } else {
            this.tokenRefresh();
          }
          // increment counter
          this.data = this.data + 1;
        }
      }
    });
  }

  tokenRefresh() {
    return this.restApi.tokenRefresh().subscribe((data: any) => {
    },
      error => {
        this.error = error;
      });
  }
}
