import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor, HttpResponseInterceptor} from './_helpers';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DidYouKnowComponent} from './home/did-you-know/did-you-know.component';
import {HomeComponent} from './home/home.component';
import {StatementsComponent} from './statements/statements.component';
import {ManageCardholdersComponent} from './manage-cardholders/manage-cardholders.component';
import {ProductOfferingComponent} from './product-offering/product-offering.component';
import {HelpComponent} from './help/help.component';
import {AccountManagementComponent} from './home/account-management/account-management.component';
import {CardManagementComponent} from './home/card-management/card-management.component';
import {WelcomeComponent} from './home/welcome/welcome.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatListModule} from '@angular/material/list';
import {HelpContactInfoComponent} from './help/help-contact-info/help-contact-info.component';
import {LogSupportQueryComponent} from './help/log-support-query/log-support-query.component';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {TransactionComponent} from './statements/transactions/transaction.component';
import {BusinessCreditCorporateChargeCardsComponent} from './product-offering/business-credit-corporate-charge-cards/business-credit-corporate-charge-cards.component';
import {AviationCardComponent} from './product-offering/aviation-card/aviation-card.component';
import {ForexLodgeCardComponent} from './product-offering/forex-lodge-card/forex-lodge-card.component';
import {GarageCardComponent} from './product-offering/garage-card/garage-card.component';
import {ProcurementCardComponent} from './product-offering/procurement-card/procurement-card.component';
import {TravelLodgeCardComponent} from './product-offering/travel-lodge-card/travel-lodge-card.component';
import {RollUpNonRollUpComponent} from './product-offering/roll-up-non-roll-up/roll-up-non-roll-up.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ReactiveFormsModule} from '@angular/forms';
import {VersionComponent} from './version/version.component';
import {MatTableModule} from '@angular/material/table';
import {FormsModule} from '@angular/forms';
import {NavigationComponent} from './product-offering/navigation/navigation.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MomentDateModule} from '@angular/material-moment-adapter';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DialogBoxComponent} from './home/card-management/dialog-box/dialog-box.component';
import {DialogBoxCancelComponent} from './home/card-management/dialog-box-cancel/dialog-box-cancel.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxCurrencyModule} from 'ngx-currency';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ChartsModule} from 'ng2-charts';
import {UsersComponent} from './manage-cardholders/users/users.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { NotificationComponent } from './notification/notification.component';
import { environment } from '../environments/environment';
import { environmentProd } from '../environments/environment.prod';
import { Requestor, FetchRequestor } from '@openid/appauth';
import {AuthorizationService} from './authorization.service';
import { CallbackComponent } from './callback/callback.component';
import { IdComponent } from './id/id.component';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { CookieModule } from 'ngx-cookie';
import { OverviewComponent } from './product-offering/overview/overview.component';
import { BusinessCreditCardComponent } from './product-offering/business-credit-card/business-credit-card.component';
import { CorporateChargeCardComponent } from './product-offering/corporate-charge-card/corporate-charge-card.component';

export const appRoutes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'help', component: HelpComponent},
  {path: 'callback', component: CallbackComponent },
  {path: 'id', component: IdComponent },
  {path: 'managecardholders', component: ManageCardholdersComponent},
  {
    path: 'productoffering', children: [
      {path: '', component: ProductOfferingComponent},
      {path: 'overview', component: OverviewComponent},
      {path: 'aviationCard', component: AviationCardComponent},
      {path: 'businessCreditCards', component: BusinessCreditCardComponent},
      {path: 'businessChargeCards', component: CorporateChargeCardComponent},
      {path: 'forexCard', component: ForexLodgeCardComponent},
      {path: 'garageCard', component: GarageCardComponent},
      {path: 'procurementCard', component: ProcurementCardComponent},
      {path: 'travelCard', component: TravelLodgeCardComponent},
      {path: 'rollUpNonRollUp', component: RollUpNonRollUpComponent},
    ]
  },
  {path: 'statements', component: StatementsComponent},
  {path: 'version', component: VersionComponent},

  {
    path: '',
    component: LoginComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    DidYouKnowComponent,
    HomeComponent,
    StatementsComponent,
    ManageCardholdersComponent,
    ProductOfferingComponent,
    HelpComponent,
    AccountManagementComponent,
    CardManagementComponent,
    WelcomeComponent,
    HelpContactInfoComponent,
    LogSupportQueryComponent,
    TransactionComponent,
    BusinessCreditCorporateChargeCardsComponent,
    AviationCardComponent,
    ForexLodgeCardComponent,
    GarageCardComponent,
    ProcurementCardComponent,
    TravelLodgeCardComponent,
    RollUpNonRollUpComponent,
    VersionComponent,
    NavigationComponent,
    DialogBoxComponent,
    DialogBoxCancelComponent,
    UsersComponent,
    HeaderComponent,
    LoginComponent,
    NotificationComponent,
    CallbackComponent,
    IdComponent,
    OverviewComponent,
    BusinessCreditCardComponent,
    CorporateChargeCardComponent,
  ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      MatCardModule,
      MatTabsModule,
      MatSelectModule,
      MatFormFieldModule,
      MatInputModule,
      MatCheckboxModule,
      MatButtonModule,
      MatListModule,
      HttpClientModule,
      MatSnackBarModule,
      ReactiveFormsModule,
      MatTableModule,
      FormsModule,
      MatTableModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MomentDateModule,
      MatPaginatorModule,
      MatToolbarModule,
      MatSlideToggleModule,
      MatPaginatorModule,
      MatDialogModule,
      MatProgressSpinnerModule,
      MatSlideToggleModule,
      NgxCurrencyModule,
      NgbModule,
      ChartsModule,
      CookieModule.forRoot(),
      RouterModule.forRoot(
        appRoutes,
        {enableTracing: true,
          scrollPositionRestoration: 'enabled',
          preloadingStrategy: PreloadAllModules,
          onSameUrlNavigation: 'reload',
        } // <-- debugging purposes only
      ),
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true},
    AuthorizationService,
    { provide: Requestor, useValue: new FetchRequestor()},
    { provide: 'AuthorizationConfig', useValue: environment},
    { provide: 'AuthorizationConfigProd', useValue: environmentProd},
  ], bootstrap: [AppComponent]
})
export class AppModule {
}
