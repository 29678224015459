<div>
  <div class="header"><img id="sbglogo" src="../assets/standardbank-logo.png">
    <app-version hidden></app-version>
  </div>
  <div class="background">
    <div class="boxposition">
      <mat-card class="loginBox">
        <div class="loginHeading">Corporate Card Portal</div>
        <table class="table-2" summary="align">
          <tr><th id="header1"></th></tr>
          <tr>
            <td>
              <div class="dot">
                <img src="../assets/icn-pencil.png"
                     srcset="../assets/icn-pencil@2x.png 2x,
                 ../assets/icn-pencil@3x.png 3x"
                     class="icn_pencil" alt="Pencil">
              </div>
            </td>
            <td>
              <div class="Line"></div>
            </td>
            <td>
              <div class="dot-2"><span class="t5default">2</span></div>
            </td>
            <td>
              <div class="Line"></div>
            </td>
            <td>
              <div class="dot-2"><span class="t5default">3</span></div>
            </td>
          </tr>
        </table>
        <table summary="align">
          <tr><th id="header2"></th></tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
        <table summary="align">
          <tr><th id="header3"></th></tr>
          <tr>
            <span class="Primary-line">Step 1:</span>
          </tr>
          <tr>
            <span class="Secondary-line">Create your online profile</span>
          </tr>
        </table>
        <table summary="align">
          <tr><th id="header4"></th></tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
        <table summary="align">
          <tr><th id="header5"></th></tr>
          <tr>
            <span class="Primary-line">Step 2:</span>
          </tr>
          <tr>
            <span class="Secondary-line">Register your company</span>
          </tr>
        </table>
        <table summary="align">
          <tr><th id="header6"></th></tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
        <table summary="align">
          <tr><th id="header7"></th></tr>
          <tr>
            <span class="Primary-line">Step 3:</span>
          </tr>
          <tr>
            <span class="Secondary-line">Approval to the portal takes up to 48 Hours</span>
          </tr>
        </table>
        <table summary="align">
          <tr><th id="header8"></th></tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
        <table class="table-3" summary="align">
          <tr><th id="header9"></th></tr>
          <tr>
            <td>
              <button class="loginButton" mat-raised-button
                      (click)="dtm.clickEventLogout('informational', 'login', 'corporate card login | login button click');authorizationService.authorize()">LET’S GET STARTED</button>
            </td>
          </tr>
        </table>
      </mat-card>
    </div>
  </div>
</div>
