<div class="row">
  <div class="col-6 p-3 pt-4">
    <div class="pb-3">
      <div class="AccountSearch bg-light pl-4">
        <div class="whichAccount row">
          <img src="../../../../assets/switchIcon.png" height="30" width="30"/>
          <p class="Which-Account-Dashbo">Which Account Dashboard Would You Like To Display?</p>
        </div>
        <mat-spinner [diameter]="70" class="daSpina" *ngIf="loadingCompanyList"></mat-spinner>
        <div *ngIf="!loadingCompanyList">
          <mat-form-field class="w-100 col-9">
            <mat-label>Company Name</mat-label>
            <mat-select [(value)]="selectedCompany"
                        (click)="dtm.clickEvent('informational', 'account selector', 'corporate card dashboard | account management | which account dashboard would you like to display dropdown click')">
              <mat-option *ngFor="let company of companies" [value]="company">
                {{company.CompanyName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button class="displayCompanyButton" mat-raised-button
                  (click)="getSecureList(selectedCompany.CompanyName, selectedCompany.CompanyId, selectedCompany.CompanySublevelNo, selectedCompany.CompanySublevelId);
dtm.clickEvent('informational', 'account selector', 'corporate card dashboard | account management | which account dashboard would you like to display button click')">
            Display
          </button>
        </div>
      </div>
    </div>

    <mat-card class="summaryDetails">
      <mat-spinner [diameter]="70" class="daSpina" *ngIf="loadingAccountList"></mat-spinner>
      <mat-card-content *ngIf="!loadingAccountList">
        <div class="row">
          <div class="walletIconBackground">
            <img src="../../../../assets/walletIcon.png" height="25" width="25"/>
          </div>
          <div class="col">
            <div class="companyName">{{selectedCompanyName}}</div>
          </div>
        </div>
        <hr class="greyHardRule">
        <div class="row">
          <div class="col-6">
            <div class="label">Available balance</div>
            <div class="info availableBalance">R {{summary.AvailableBalance}}</div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-2">
                <img src="../../../../assets/personIcon.png" height="40" width="40"/>
              </div>
              <div class="col-10 contactPerson">
                <div>CONTACT PERSON</div>
                <div>{{summary.ContactName1}}</div>
              </div>
            </div>
          </div>
        </div>
        <hr class="greyHardRule">
        <div class="row">
          <div class="col-6">
            <div class="label">Instalment amount</div>
            <div class="info">R {{summary.InstallmentAmount}}</div>
          </div>
          <div class="col-6">
            <div class="label">Outstanding balance</div>
            <div class="info">R {{summary.LatestBalance}}</div>
          </div>
        </div>
        <hr class="greyHardRule">
        <div class="row">
          <div class="col-6">
            <div class="label">Amount in arrears</div>
            <div class="info">R {{summary.ArrearsAmount}}</div>
          </div>
          <div class="col-6">
            <div class="label">Credit limit</div>
            <div class="info">R {{summary.CreditLimit}}</div>
          </div>
        </div>
        <hr class="greyHardRule">
        <div class="row">
          <div class="col-6">
            <div class="label">Outstanding payment amount</div>
            <div class="info">R {{summary.OutstandingPaymentAmount}}</div>
          </div>
          <div class="col-6">
            <div class="label">Accrued credit</div>
            <div class="info">R {{summary.AccruedCredit}}</div>
          </div>
        </div>
        <hr class="greyHardRule">
        <div class="row">
          <div class="col-6">
            <div class="label">Reserve funds</div>
            <div class="info">R {{summary.ReserveFunds}}</div>
          </div>
          <div class="col-6">
            <div class="row" hidden>
              <div class="col-7 pt-2 label">MONTHLY STATEMENT</div>
              <img src="../../../../assets/csvIcon.png" height="35" width="35"/>
              <img src="../../../../assets/pdfIcon.png" height="35" width="35"/>
            </div>
          </div>
        </div>
        <hr class="greyHardRule">
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-6 p-4">
    <mat-card class="mat-card-notification notifications">
      <mat-card-header>
        <mat-card-title>ACCOUNT NOTIFICATIONS</mat-card-title>
      </mat-card-header>
      <mat-spinner [diameter]="70" class="daSpina" *ngIf="loadingNotificationList"></mat-spinner>
      <mat-card-content *ngIf="!loadingNotificationList">
        <div class="noNotifications" *ngIf="!notifications">No Notifications associated with this company within the
          last 6 months
        </div>
        <div class="notification" *ngFor="let notification of notifications">
          <div class="row">
            <div class="col-6">
              <div class="notificationKey">Date:</div>
              <!--<div class="notificationKey">Account:</div>-->
              <div class="notificationKey">Change:</div>
            </div>
            <div class="col-6">
              <div class="notificationValue">{{notification.Date}}</div>
              <!--<div class="notificationValue">{{notification.MaskedNumber}}</div>-->
              <div class="notificationValue">{{notification.Change}}
              </div>
            </div>
          </div>
          <hr class="greyHardRuleNotification">
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col pt-4">
    <mat-card class="spend-mat-card">
      <mat-card-header>
        <mat-card-title>Spend Analysis</mat-card-title>
      </mat-card-header>
      <mat-card-content class="spendData">

        <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
          <mat-form-field class="spendDropDown">
            <mat-label>Select Analysis Type</mat-label>
            <mat-select formControlName="analysisType"
                        (click)="dtm.clickEvent('informational', 'analysis type selector', 'corporate card dashboard | account management | select analysis type dropdown click')">
              <mat-option *ngFor="let analysisType of analysisTypes" [value]="analysisType">{{ analysisType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="spendDropDown">
            <mat-label>Select Card Holder</mat-label>
            <mat-select (selectionChange)="getAssociatedCardNumbers($event)" formControlName="cardHolder"
                        (click)="dtm.clickEvent('informational', 'analysis card holder selector', 'corporate card dashboard | account management | select card holder dropdown click')">
              <mat-option *ngFor="let cardHolder of cardHolderList" [value]="cardHolder">{{ cardHolder }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="spendDropDown">
            <mat-label>Select Card Number</mat-label>
            <mat-select formControlName="cardNumber"
                        (click)="dtm.clickEvent('informational', 'card number selector', 'corporate card dashboard | account management | select card number dropdown click')">
              <mat-option *ngFor="let cardNumber of associatedCards" [value]="cardNumber.cardNo">{{ cardNumber.maskedCardNo }}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="button-row">
            <button class="sbg-button" (click)="resetValues();
dtm.clickEvent('informational', 'spend analysis', 'corporate card dashboard | account management | select analysis reset button click')"
            >RESET</button>
            <button class="sbg-button" [disabled]="!formGroup.valid" type="submit"
                    (click)="dtm.clickEvent('informational', 'spend analysis', 'corporate card dashboard | account management | select analysis display button click')"
            >DISPLAY</button>
          </div>
        </form>

        <div class="trendsHeading">Cardholder Trends</div>

        <div class="row">
          <div class="col">
            <div class="legend">CREDIT LIMIT</div>
            <div class="legend">AVAILABLE BALANCE</div>
            <div class="legend">&nbsp;</div>
          </div>

          <div class="barChart">
            <canvas baseChart height="60"
                    [datasets]="barChartData"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="barChartLegend"
                    [chartType]="barChartType"
                    [colors]="barChartColors">
            </canvas>

          </div>
          <div class="cover">
            <div class="row key">
              <div class="legendItems">Spend Analysis:</div>
              <div class="Oval-blue"></div>
              <div class="legendItems keyDesc">CREDIT LIMIT</div>
              <div class="Oval-greenBlue"></div>
              <div class="legendItems keyDesc">AVAILABLE</div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
