import {Component, OnInit, Input} from '@angular/core';
import {DtmAnalyticsServiceService} from '../../dtm-analytics-service.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit{
  @Input() currentUser: string;

  constructor(private dtm: DtmAnalyticsServiceService) {
  }

  ngOnInit() {
    this.dtm.updatePageProperties('business:corporate card dashboard');
    this.dtm.updateDataLayerForPage();
  }
}
