<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Procurement Card</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <mat-tab-group dynamicHeight class="col-9 w-100">
      <mat-tab label="PROCUREMENT CARD">
        <div class="row pt-5">
          <img class="col-4 w-100 h-100" src="../../../assets/manWomanLookingAtLaptop.png" alt="Image of man and woman">
          <div class="col-4">
            <h4 class="SubHeading">Procurement Card</h4>
            <div class="TextContent">
              <p>
                The Procurement Card conveniently facilitates business-to-business integrated purchasing. It’s a payment
                system that simplifies procurement with the versatility and acceptance of a credit card, while allowing
                immediate settlement with suppliers.
              </p>
              <p>
                Organisations can track and control day-to-day expenditure with an auditable online expense management
                tool.
              </p>
            </div>
          </div>
          <div class="col-4">
            <img class="w-100" src="../../../assets/procurementCard.png" alt="Image of procurement cards">
            <button mat-raised-button color="primary" hidden>APPLY NOW</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
