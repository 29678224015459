import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { environmentProd } from '../../environments/environment.prod';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {

  currentApplicationVersion: string;

  constructor() { }

  ngOnInit(): void {
    if (document.location.hostname === environment.prod_url) {
      this.currentApplicationVersion = environmentProd.appVersion;
    } else {
      this.currentApplicationVersion = environment.appVersion;
    }
  }

}
