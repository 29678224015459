import { AuthorizationConfig, GeneralEnvironmentInfo } from '../app/authorization_config';

export const environment: AuthorizationConfig & GeneralEnvironmentInfo  = {
  production: false,
  appVersion: require('../../package.json').version,
  destEmail: 'jurgen.schatz@standardbank.co.za',
  fromEmail: 'corporate-card@standardbank.co.za',
  issuer_uri: 'https://enterprisestssit.standardbank.co.za',
  client_id: 'f70d4ed2-38bb-48a5-828b-e34894851ffe',
  redirect_uri: 'https://' + window.location.hostname.toLowerCase() + '/callback',
  // redirect_uri: 'http://localhost:4200/callback',
  response_type: 'code',
  scope: 'openid email profile',
  code_challenge: 'HsaFcD6QzAR1DBccrWf9Vm1dytXHolG1snFtSLj54OQ',
  code_challenge_method: 'S256',
  prod_url: 'corporatecard.standardbank.co.za',
};
