import {Component, OnInit} from '@angular/core';
import {Card} from '../_models/card';
import {RestApiService} from '../_services/rest-client-service';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';
import {AuthorizationService} from '../authorization.service';
import {Router} from '@angular/router';
import {CompanySummary} from '../_models/companySummary';
import { Summary } from '@angular/compiler';

const LS_USER_INFO      = 'authorization.service.user_info';
declare var _satellite;
declare var dataLayer;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private restApi: RestApiService, public dtm: DtmAnalyticsServiceService,
              public authorizationService: AuthorizationService, public router: Router) {
    this.authorized = false;
    this.userInfo = null;
  }
  cardData: {card: Card[]; summary: CompanySummary}
  error = '';
  isLoggedIn = false;
  currentUser = '';
  public authorized: boolean;

  public userInfo;
  public tokenResponseJSON: any;

  ngOnInit(): void {
    // console.log('constructor for home!');
    this.isLoggedIn = true;
    this.currentUser = sessionStorage.getItem('currentUser');
    // console.log('currentUser >' + this.currentUser + '<');
    if (this.currentUser === null) {
      sessionStorage.clear();
      this.authorizationService.signOut();
      this.router.navigateByUrl('/');
      if (typeof dataLayer === 'undefined' || typeof _satellite === 'undefined') {
        window.location.reload();
      }
    }
  }
}



