import {Component, OnInit, Input, ViewChild, OnChanges} from '@angular/core';
import {Card} from '../../_models/card';
import {MatTableDataSource} from '@angular/material/table';
import {MatTable} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
// import {MatCheckbox} from '@angular/material/checkbox';
// import { MatCheckboxModule } from '@angular/material/checkbox';
import {RestApiService} from '../../_services/rest-client-service';
import {MatPaginator} from '@angular/material/paginator';
import {DialogBoxComponent} from './dialog-box/dialog-box.component';
import {DialogBoxCancelComponent} from './dialog-box-cancel/dialog-box-cancel.component';
import {FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, FormControl} from '@angular/forms';
import {MaskedCard} from '../../_models/maskedCard';
import {DtmAnalyticsServiceService} from '../../dtm-analytics-service.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompanySummary} from '../../_models/companySummary';
import {Company} from '../../_models/company';


@Component({
  selector: 'app-card-management',
  templateUrl: './card-management.component.html',
  styleUrls: ['./card-management.component.css']
})
export class CardManagementComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['cardHolder', 'cardNumber', 'spend', 'available', 'allocatedTo', 
  'allocatedAmount', 'cardLimitAmount', 'action', 'maskedCard'];
  @Input() cardData: { card: Card[]; summary: CompanySummary}
  dataSource: any;
  cardHolderList: MaskedCard[] = [];
  cardHolder = '';
  allocatedTo = '';
  amount = '';
  maskedNumber = '';
  viewActive = 'ACTIVE';
  filteredCardData: Card[];
  cardSearchHolderList: string[] = [];
  associatedCards: MaskedCard[] = [];
  cardSearchNumbers: MaskedCard[] = [];
  singleCardView = false;
  viewCardSearch = true;
  singleCard: Card;
  error = '';
  allCards: Card[];
  cardLimitAmount: number;
  creditLimit: number;
  creditAllocated: number;
  changeCreditAllocated: number;
  forbiddenValues: string[];
  minLenth = 4;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  cardsFormGroup: FormGroup = new FormGroup({});

  constructor(private snackBar: MatSnackBar, public dialog: MatDialog, public restService: RestApiService, 
    private formBuilder: FormBuilder,
    public dtm: DtmAnalyticsServiceService) {
    this.cardsFormGroup = this.formBuilder.group({
      cardHolder: ['', ],
      cardNumber: ['', ],
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    this.createTransferLimitList();
  }

  filterCardView($event) {
    if ($event.checked === false) {
      this.viewActive = 'ACTIVE';
    } else {
      this.viewActive = 'INACTIVE';
    }
    this.cardHolderList = [];
    this.createTransferLimitList();
  }

  createTransferLimitList() {
    if (this.cardData !== undefined) {
      this.filteredCardData = this.cardData.card;
      this.creditAllocated = 0;
      for (let i = 0; i < this.cardData.card.length; i++) {
        this.creditAllocated = this.creditAllocated + this.cardData.card[i].CreditLimit
      }
      this.creditAllocated = this.cardData.summary.CreditLimit - this.creditAllocated
      this.changeCreditAllocated = this.creditAllocated;
      if (this.viewActive === 'INACTIVE') {
        for (let i = 0; i < this.cardData.card.length; i++) {
          this.cardData.card = this.cardData.card.filter(function (obj) {
            return obj.Status === 'INACTIVE';
          });
        }
      } else {
        for (let i = 0; i < this.cardData.card.length; i++) {
          this.cardData.card = this.cardData.card.filter(function (obj) {
            return obj.Status === 'ACTIVE';
          });
        }
      }
      this.createCardInfoListForCardList(this.cardData.card);
      this.createCardNumberList(this.filteredCardData);
      this.dataSource = new MatTableDataSource(this.cardData.card);
      this.dataSource.paginator = this.paginator;
      // for (let i = 0; i < this.dataSource.data.length; i++) {
      //   // console.log("rec", i, this.dataSource.data);
      //   // this.dataSource.data.SelectRow = true;
      //   this.table.renderRows();
      // }

      this.cardData.card = this.filteredCardData;
      this.creditLimit = this.cardData.summary.CreditLimit;
    }
  }

  changeCardSearchView() {
    this.viewCardSearch = !this.viewCardSearch;
  }

  changeSingleCardView() {
    this.singleCardView = !this.singleCardView;
  }

  onCardsFormSubmit() {
    if (this.cardsFormGroup.value.cardHolder && this.cardsFormGroup.value.cardNumber) {
      this.singleCard = this.cardData.card.find(o => o.Name === this.cardsFormGroup.value.cardHolder && o.Account === this.cardsFormGroup.value.cardNumber);
    } else if (this.cardsFormGroup.value.cardNumber) {
      this.singleCard = this.cardData.card.find(o => o.Account === this.cardsFormGroup.value.cardNumber);
    } else if (this.cardsFormGroup.value.cardHolder) {
      this.singleCard = this.cardData.card.find(o => o.Name === this.cardsFormGroup.value.cardHolder);
    } else {
      return;
    }
    if (this.singleCardView !== true) {
      this.changeSingleCardView();
    }
  }

  openDialog(action, obj) {
    obj.action = action;
    obj.amount = this.amount;
    obj.maskedCardNo = this.maskedNumber;
    var dialogRef: any;
    if (action === "Card Cancellation") {
      dialogRef = this.dialog.open(DialogBoxCancelComponent, {
        width: '400px',
        data: obj
      });
    } else {
      dialogRef = this.dialog.open(DialogBoxComponent, {
        width: '400px',
        data: obj
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Card Cancellation') {
        this.cancelCard(result.data);
      } else if (result.event === 'Limit Allocation') {
        var anchors = document.getElementsByTagName("body");
        document.body.style.cursor = "wait";
        this.transferLimit(result.data);
      } else if (result.event === 'CANCEL') {
        // console.log(result.data);
        if (result.data.allocatedAmount || result.data.cardLimitAmount) {
          this.updateAvailable(result.data, 0);
          this.changeCreditAllocatedUpdate(Element);
        }
      }
    });
  }

  cancelCard(result) {
    this.restService.cancelCard(result.Account).subscribe((data: any) => {
        this.removeCard(result.Account);
      },
      error => {
        this.error = error;
      });
  }

  openFailureSnackBar(message: string) {
    this.snackBar.open(message, 'Dismiss', {
      duration: 10000,
      panelClass: ['red-snackbar', 'login-snackbar'],
      verticalPosition: 'top',
    });
  }



  transferLimit(result) {
    let source = this.allCards.find(o => o.Account === result.Account);
    let destination = this.filteredCardData.find(o => o.Account === result.allocatedTo);
    if (result.allocatedTo !== undefined && source.Account !== undefined && source.Account == destination.Account) {
      this.error = "From account same as to account"
      return this.openFailureSnackBar('From account same as allocated account');
    }

    let allocatConversion = 0;
    if (result.allocatedAmount !== undefined) {
      allocatConversion = parseInt(result.allocatedAmount, 10);
    }
    let cardLimitAmountConversion = 0;
    if (result.cardLimitAmount !== undefined) {
      cardLimitAmountConversion = parseInt(result.cardLimitAmount, 10); 
    }
    if (result.allocatedTo && result.allocatedAmount) {
      let sourceAmount = source.CreditLimit - allocatConversion + cardLimitAmountConversion;
      let destinationAmount = destination.CreditLimit + allocatConversion;
      // console.log("sourceAmount", sourceAmount.toString());
      // console.log("destinationAmount", destinationAmount.toString());
      return this.restService.limitTransfer(result.Account, sourceAmount.toString(), result.allocatedTo, destinationAmount.toString()).subscribe((data: any) => {
          var anchors = document.getElementsByTagName("body");
          document.body.style.cursor = "pointer";
        },
        error => {
          this.updateAvailable(result, 0);
          this.error = error;
          var anchors = document.getElementsByTagName("body");
          document.body.style.cursor = "pointer";
        });
    } else {
      const addedAmount = parseInt(result.CreditLimit) + parseInt(result.cardLimitAmount);
      // console.log("addedAmount", addedAmount.toString());
      return this.restService.limitIncrease(result.Account, addedAmount.toString()).subscribe((data: any) => {
          // this.createTransferLimitList();
          this.updateAvailable(result, addedAmount);
          var anchors = document.getElementsByTagName("body");
          document.body.style.cursor = "pointer";
        },
        error => {
          this.updateAvailable(result, 0);
          this.error = error;
          var anchors = document.getElementsByTagName("body");
          document.body.style.cursor = "pointer";
        });
    }
  }

  createCardNumberList(cards: Card[]) {
    this.cardHolderList = [];
    for (let card of cards) {
      let maskedCard = new MaskedCard();
      maskedCard.cardNo = card.Account;
      maskedCard.maskedCardNo = card.MaskedCardNumber;
      this.cardHolderList.push(maskedCard);
    }
  }

  getAssociatedCardNumbers($event) {
    this.associatedCards = [];
    if ($event.source.value !== "") {
      for (let card of this.cardSearchNumbers) {
        if (card.cardHolder === $event.source.value) {
          this.associatedCards.push(card);
        }
      }
    }
  }

  removeCard(value) {
    this.cardData.card = this.cardData.card.filter(function (obj) {
      return obj.Account !== value;
    });
    this.createTransferLimitList();
  }

  createCardInfoListForCardList(cards: Card[]) {
    this.allCards = cards;
    this.cardSearchHolderList = [];
    this.cardSearchNumbers = [];
    if (cards) {
      for (let card of cards) {
        let maskedCard = new MaskedCard();
        this.cardSearchHolderList.push(card.Name);
        maskedCard.cardHolder = card.Name;
        maskedCard.cardNo = card.Account;
        maskedCard.maskedCardNo = card.MaskedCardNumber;
        this.cardSearchNumbers.push(maskedCard);
      }
    }
  }

  public inTheGreen(value: number): boolean {
    if (value >= 0) {
      return true;
    }
    return false;
  }

  public changeCreditAllocatedUpdate(obj) {
    this.changeCreditAllocated = this.cardData.summary.CreditLimit;
    let creditAllocated = 0;
    for (let i = 0; i < this.cardData.card.length; i++) {
      creditAllocated = creditAllocated + this.cardData.card[i].CreditLimit
    }
    this.changeCreditAllocated = this.cardData.summary.CreditLimit - creditAllocated
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      if (this.dataSource.filteredData[i].cardLimitAmount !== undefined) {
        let number = this.dataSource.filteredData[i].cardLimitAmount === null ? 0 : this.dataSource.filteredData[i].cardLimitAmount
        let numberConversion = parseInt(number, 10);
        this.changeCreditAllocated = this.changeCreditAllocated - numberConversion;
      }
    }
  }

  public updateAvailable(obj, newLimit: number) {
    let AllocatedTo: string[] = [];
    let AllocateAmount: number[] = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      // console.log('in loop:', i, this.dataSource.filteredData[i].allocatedTo, this.dataSource.filteredData[i].allocatedAmount);
      if (this.dataSource.filteredData[i].allocatedTo !== undefined && this.dataSource.filteredData[i].allocatedAmount !== undefined) {
        let found_short_list = 'n';
        let x = 0;
        for (; x < AllocatedTo.length; x++) {
          if (AllocatedTo[x] === this.dataSource.filteredData[i].allocatedTo) {
            found_short_list = 'y';
            break;
          }
        }
        if (found_short_list === 'n') {
          AllocatedTo.push(this.dataSource.filteredData[i].allocatedTo);
          if (this.dataSource.filteredData[i].allocatedAmount) {
            AllocateAmount.push(Number(this.dataSource.filteredData[i].allocatedAmount));
          } else {
            AllocateAmount.push(0);
          }
        } else {
          if (this.dataSource.filteredData[i].allocatedAmount) {
            AllocateAmount[x] = AllocateAmount[x] + Number(this.dataSource.filteredData[i].allocatedAmount);
          }
        }
        // console.log('found', this.dataSource.filteredData[i].allocatedTo, this.dataSource.filteredData[i].allocatedAmount)
      } else {
        if (this.dataSource.filteredData[i].Account) {
          AllocatedTo.push(this.dataSource.filteredData[i].Account);
          // console.log('other', this.dataSource.filteredData[i].Account)
          AllocateAmount.push(0);
        }
      }
    }
    for (let i = 0; i < this.cardData.card.length; i++) {
      let found = 'n';
      let j = 0;
      for (; j < AllocatedTo.length; j++) {
        if (this.cardData.card[i].Account === AllocatedTo[j]) {
          found = 'y';
          break;
        }
      }
      if (found === 'y') {
        let result = 0;
        let result2 = 0;
        if (newLimit !== undefined) {
          if (newLimit !== 0) {
            // console.log('set newLimit:', newLimit)
            this.cardData.card[i].CreditLimit = newLimit;
          }
          this.cardData.card[i].AvailableBalance = this.cardData.card[i].CreditLimit +
            (result = this.cardData.card[i].AllocateAmount === undefined ? 0 : Number(this.cardData.card[i].AllocateAmount)) -
            this.cardData.card[i].LatestBalance + Number(AllocateAmount[j]);
        }
      }
    }
  }
  
  public checkCreditAllocated(obj) {
    if (obj.Account == obj.allocatedTo) {
      this.minLenth = 20; // if trying to allocate to same account this will set an error
    } else {
      this.minLenth = 4;  // this will clear same account error
    }
  }

  public emptyFields(obj) {
    if (obj.allocatedAmount) {
      obj.allocatedAmount = null;
    }
    if (obj.cardLimitAmount) {
      obj.cardLimitAmount = null;
    }
    if (obj.allocatedTo) {
      obj.allocatedTo = null;
    }
  }
}
