<div class="VERT-TAB-BG">
  <div class="Log-a-Support-Query">Log a Support Query</div>
  <div class="Easily-log-a-support">Easily log a support request and we get back to you as soon as we can</div>
  <form name="help log support query form" [formGroup]="queryForm" (ngSubmit)="onSubmit()" data-frmcontainer="CC | help log support query">
  <div class="row">
    <mat-form-field class="inputField" (click)="dtm.updateDataLayerForPageWithForm()">
      <mat-label>Your name</mat-label>
      <input matInput placeholder="John Doe" formControlName="name" required>
      <mat-error
        *ngIf="queryForm.get('name').hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inputField">
      <mat-label>Your contact number</mat-label>
      <input matInput placeholder="0812345678" (keypress)="numberOnly($event)" formControlName="number" required>
      <mat-error
        *ngIf="queryForm.get('number').hasError('required')">
        Contact number is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="inputField">
      <mat-label>Your email address</mat-label>
      <input matInput placeholder="john.doe@gmail.com" formControlName="email" required>
      <mat-error
        *ngIf="queryForm.get('email').hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inputField">
      <mat-label>Type of query</mat-label>
      <input matInput placeholder="Account Related" formControlName="queryType" required>
      <mat-error
        *ngIf="queryForm.get('queryType').hasError('required')">
        Query type is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="messageInput">
      <mat-label>Message</mat-label>
      <input matInput formControlName="message">
    </mat-form-field>
  </div>
  <div class="row">
      <button class="buttonOutline" [style.border]="!queryForm.valid ? 'solid 1px grey' : ''" [disabled]="!queryForm.valid" mat-button type="submit"
              (click)="dtm.clickEvent('confirmational', 'log support query', 'corporate card dashboard | help | log support query submit button click')">
        <div class="buttonText" [style.color]="!queryForm.valid ? 'grey' : ''">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          SUBMIT
        </div>
      </button>
  </div>
  </form>
</div>
