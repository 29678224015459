<div>
  <app-header [(currentUser)]="currentUser"></app-header>
</div>

<div class="content">
  <div class="row">
    <div class="col-6">
      <app-welcome [(currentUser)]="currentUser"></app-welcome>
    </div>
    <div class="col-6">
      <app-did-you-know></app-did-you-know>
    </div>
  </div>

  <div class="row pt-4">
    <mat-tab-group dynamicHeight class="w-100" (selectedTabChange)="dtm.clickEvent('navigational', 'tab link', 'corporate card dashboard | product offering | ' +$event.tab.textLabel + ' list link click')">
      <mat-tab label="ACCOUNT MANAGEMENT">
        <app-account-management (redirect)="cardData=$event"></app-account-management>
      </mat-tab>
      <mat-tab label="CARD MANAGEMENT">
        <app-card-management [cardData]="cardData"></app-card-management>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and
    Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number
    NCRCP15
  </div>
</footer>
