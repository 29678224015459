import {Component, OnInit, ViewChild, Input, ChangeDetectorRef} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {RestApiService} from '../../_services/rest-client-service';
import {Card} from '../../_models/card';
import {CompanySummary} from '../../_models/companySummary';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Transaction, Transactions} from '../../_models/transaction';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {TableUtil} from './TableUtil';
import {TableUtilsCSV} from './TableUtilsCSV';
import {MaskedCard} from '../../_models/maskedCard';
import {formatDate} from '@angular/common';
import {DataStorageService} from '../../_services/data-storage.service';
import {DtmAnalyticsServiceService} from '../../dtm-analytics-service.service';


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-transaction-results',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ]
})
export class TransactionComponent implements OnInit {
  error = '';
  displayedColumns: string[] = ['id', 'date', 'merchant_info', 'item_amount', 'country', 'ind', 'tran_type', 'currency'];
  responseData: any;
  summary: CompanySummary;
  cards: Card[];
  dataSource: any;
  formGroup: FormGroup = new FormGroup({});
  fromDate: string;
  toDate: string;
  names: string[];
  associatedCards: MaskedCard[] = [];
  cardNumbers: MaskedCard[] = [];
  loadingCardList = false;
  loadingTransactionList = false;
  transactionData: Transactions = new Transactions();

  @Input() max: any;
  today = new Date();


  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private restApi: RestApiService, private formBuilder: FormBuilder, private dataStore: DataStorageService,
              public dtm: DtmAnalyticsServiceService, private changeDetectorRefs: ChangeDetectorRef) {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      cardNumber: ['', Validators.required],
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.loadingCardList = true;
    this.names = [];
    this.cardNumbers = [];
    this.summary = this.dataStore.getCompanySummary();

    if (this.summary === undefined) {
      this.getCompanySummary();
    } else {
      this.cards = this.summary.Cards;
      this.userFriendlyCardMask(this.cards);
      this.createCardNumAndNamesLists(this.cards);
      this.loadingCardList = false;
    }
  }

  getCompanySummary() {
    return this.restApi.getCompanySummary().subscribe((data: {}) => {
        this.responseData = data;
        this.summary = this.responseData;
        this.cards = this.summary.Cards;
        this.userFriendlyCardMask(this.cards);
        this.createCardNumAndNamesLists(this.cards);
      },
      error => {
        this.error = error;
        this.loadingCardList = false;
      });
  }

  userFriendlyCardMask(cards: Card[]) {
    for (let card of cards) {
      let tempString = card.Account;
      card.MaskedCardNumber = tempString.replace(tempString.substr(4, 8), '******');
    }
    this.cards = cards;
  }

  getTransactionsList(cardNumber, fromDate, toDate) {
    return this.restApi.getTransactionList(cardNumber, fromDate, toDate).subscribe((data: {}) => {
      this.loadingTransactionList = false;
      this.responseData = data;

      this.changeDetectorRefs.detectChanges();
      if (this.responseData.transactions != null) {
        this.transactionData.transactions = this.responseData.transactions;
        this.dataSource = new MatTableDataSource(this.transactionData.transactions);
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource = new MatTableDataSource(this.transactionData.transactions);
        this.dataSource.data.length = 0;
      }
    }, error => {
      this.loadingTransactionList = false;
      this.error = error;
    });
  }

  get f() {
    return this.formGroup.controls;
  }

  createCardNumAndNamesLists(cards: Card[]) {
    this.names = [];
    this.cardNumbers = [];
    for (let card of cards) {
      var nameFound = false;
      for (let name of this.names) {
        if (name === card.Name) {
          nameFound = true;
          break;
        }
      }
      if (nameFound === false) {
        this.names.push(card.Name);  
      }
      let maskedCard = new MaskedCard();
      maskedCard.cardHolder = card.Name;
      maskedCard.cardNo = card.Account;
      maskedCard.maskedCardNo = card.MaskedCardNumber;
      this.cardNumbers.push(maskedCard);
    }
    this.loadingCardList = false;
  }

  getAssociatedCardNumbers($event) {
    this.associatedCards = [];
    if ($event.source.value !== '') {
      for (let card of this.cardNumbers) {
        if (card.cardHolder === $event.source.value) {
          this.associatedCards.push(card);
        }
      }
    }
  }

  exportTable() {
    const transactionsTable: Partial<Transaction>[] = this.responseData.transactions.map(x => ({
      date: x.date,
      merchant_info: x.merchant_info,
      item_amount: x.item_amount,
      country: x.country
    }));
    TableUtil.exportToPdf(transactionsTable);
  }

  exportCSVTable() {
    const transactionsTable: Partial<Transaction>[] = this.responseData.transactions.map(x => ({
      date: x.date,
      merchant_info: x.merchant_info,
      item_amount: x.item_amount,
      country: x.country
    }));
    TableUtilsCSV.exportTableToExcel(transactionsTable);
  }

  onFormSubmit() {
    if (this.formGroup.valid) {
      this.loadingTransactionList = true;
      if (this.formGroup.value.fromDate != null) {
        let fromDate = formatDate(this.formGroup.value.fromDate._d, 'yyyyMMdd', 'en-ZA')
        let toDate = formatDate(this.formGroup.value.toDate._d, 'yyyyMMdd', 'en-ZA')
        this.getTransactionsList(this.formGroup.value.cardNumber, fromDate, toDate);
      }
    }
  }

  resetValues() {
    this.dataSource = [];
    if (this.formGroup.valid) {
      this.formGroup.reset();
    }
  }

}

