<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Forex Lodge Card</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <mat-tab-group dynamicHeight class="col-9 w-100">
      <mat-tab label="FOREX LODGE CARD">
        <div class="row pt-5">
          <img class="col-4 w-100 h100" src="../../../assets/womanGettingOffPlane.png"
               alt="Image of woman with luggage">
          <div class="col-4">
            <h4 class="SubHeading">Forex Lodge Card</h4>
            <div class="TextContent">
              <p>
                Simplify the payment of foreign exchange purchases with a convenient card payment
                option, regardless of the bureau de change chosen by the company. The Forex Lodge
                Card is an automatic payment order facility that reduces the administrative burden and
                costs.
              </p>
            </div>
          </div>
          <div class="col-4">
            <img class="w-100" src="../../../assets/forexCard.png" alt="Image of forex card">
            <button mat-raised-button color="primary" hidden>APPLY NOW</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
