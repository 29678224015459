import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forex-lodge-card',
  templateUrl: './forex-lodge-card.component.html',
  styleUrls: ['./forex-lodge-card.component.css']
})
export class ForexLodgeCardComponent implements OnInit {
  currentUser: string;

  constructor() {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  ngOnInit(): void {
  }

}
