<div>
  <div class="header"><img id="sbglogo" src="../assets/standardbank-logo.png">
    <app-version hidden></app-version>
  </div>
  <div class="background">
      <div class="boxposition">
        <mat-card class="loginBox">
          <table width="100%" summary="align">
            <tr>
              <th id="header1">
                <span class="Corporate-Card-Regis">Corporate Card Registration</span>
              </th>
            </tr>
            <tr>
              <td height="400px">
                <mat-card-content>
                  <form name="help log support query form" [formGroup]="idAndCompanyRegistrationForm"  #formDirective="ngForm" (ngSubmit)="onSubmit(idAndCompanyRegistrationForm,formDirective)" data-frmcontainer="CC | help log support query">
                    <table width="100%" summary="align">
                      <tr><th id="header2"></th></tr>
                      <tr>
                        <td>
                          <div class="row background-white">
                            <mat-form-field class="example-full-width">
                              <mat-label>RSA ID</mat-label>
                              <input matInput placeholder="RSA ID" formControlName="IdNumber" required>
                              <mat-error
                                *ngIf="idAndCompanyRegistrationForm.get('IdNumber').hasError('required')">
                                Id Number is <strong>required</strong>
                              </mat-error>
                              <mat-error *ngIf="idAndCompanyRegistrationForm.get('IdNumber').hasError('pattern')">
                                Must be numeric and length of 13
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="row background-white">
                            <mat-form-field class="example-full-width">
                              <mat-label>Company Registration</mat-label>
                              <input matInput placeholder="YYYY/NNNNNN/NN" formControlName="CompanyRegistrationId" required>
                              <mat-error
                                *ngIf="idAndCompanyRegistrationForm.get('CompanyRegistrationId').hasError('required')">
                                Company Registration Id is <strong>required</strong>
                              </mat-error>
                              <mat-error *ngIf="idAndCompanyRegistrationForm.get('CompanyRegistrationId').hasError('pattern')">
                                Format YYYY/NNNNNN/NN
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="row background-white">
                            <mat-form-field class="example-full-width">
                              <mat-label>Company Name</mat-label>
                              <input matInput placeholder="Company Name" formControlName="CompanyName" required>
                              <mat-error
                                *ngIf="idAndCompanyRegistrationForm.get('CompanyName').hasError('required')">
                                Company Name Id is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="boxposition">
                            <div class="button-div">
                              <button class="loginButton" [style.border]="!idAndCompanyRegistrationForm.valid ? 'solid 1px grey' : ''" [disabled]="!idAndCompanyRegistrationForm.valid" mat-raised-button type="submit"
                                      [class.spinner]="loading"
                                      (click)="dtm.clickEventLogout('informational', 'login', 'corporate card login | login button click');">Register Company</button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </form>
                </mat-card-content>
              </td>
            </tr>
          </table>
          <mat-card-actions></mat-card-actions>
        </mat-card>
      </div>
  </div>
</div>
