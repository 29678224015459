import {Card} from './card';

export class CompanySummary {
  AvailableBalance: number;
  InstallmentAmount: number;
  ArrearsAmount: number;
  OutstandingPaymentAmount: number;
  ReserveFunds: number;
  LatestBalance: number;
  CreditLimit: number;
  AccruedCredit: number;
  Cards: Card[];
  ContactName1: string;
  BillingAccount: string;
}
