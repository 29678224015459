<div class="Rectangle">
  <div class="SearchByHeading">Search By:</div>
  <mat-spinner [diameter]="70" class="daSpina" *ngIf="loadingCardList"></mat-spinner>
  <form *ngIf="!loadingCardList" [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
    <mat-form-field class="spendDropDown">
      <mat-label>Name On Card</mat-label>
      <mat-select (selectionChange)="getAssociatedCardNumbers($event)" formControlName="name"
                  (click)="dtm.clickEvent('informational', 'transaction details selector', 'corporate card dashboard | transactions | card holder name dropdown click')">
        <mat-option *ngFor="let name of names" [value]="name">{{ name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="spendDropDown">
      <mat-label>Select Card Number</mat-label>
      <mat-select formControlName="cardNumber"
                  (click)="dtm.clickEvent('informational', 'transaction details selector', 'corporate card dashboard | transactions | card number dropdown click')">
        <mat-option *ngFor="let cardNumber of associatedCards" [value]="cardNumber.cardNo">{{ cardNumber.maskedCardNo }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="spendDropDown">
      <mat-label>From date</mat-label>
      <input matInput [matDatepicker]="picker1" [max]="today" formControlName="fromDate"
             (dateChange)="dtm.clickEvent('informational', 'transaction details selector', 'corporate card dashboard | transactions | from date picker click')">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="spendDropDown">
      <mat-label>To date</mat-label>
      <input matInput [matDatepicker]="picker2" [max]="today" formControlName="toDate"
             (dateChange)="dtm.clickEvent('informational', 'transaction details selector', 'corporate card dashboard | transactions | to date picker click')">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <div class="SubmitButtonGroup">
      <button class="ResetButton" (click)=
        "resetValues();dtm.clickEvent('informational', 'transaction details', 'corporate card dashboard | transactions | transactional search reset button click')">
        RESET</button>
      <button [disabled]="!formGroup.valid" class="SubmitButton" type="submit"
              (click)="dtm.clickEvent('informational', 'transaction details', 'corporate card dashboard | transactions | transactional search display button click')">
        DISPLAY</button>
    </div>
  </form>
</div>

<mat-card class="ListCard">
  <mat-spinner [diameter]="70" class="daSpina" *ngIf="loadingTransactionList"></mat-spinner>
  <mat-card-content *ngIf="!loadingTransactionList">
    <mat-card-header class="TransactionsHeading col-3">Transaction Results
    </mat-card-header>

    <div class="mat-elevation-z8">
      <table id="myTable" mat-table [dataSource]="dataSource" class="my-table mat-elevation-z8">

        <ng-container matColumnDef="id" hidden="true">
          <th hidden="true" mat-header-cell *matHeaderCellDef> ID</th>
          <td hidden="true" mat-cell *matCellDef="let element"> {{element.id}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> TRANS DATE</th>
          <td mat-cell *matCellDef="let element"> {{element.date}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="merchant_info">
          <th mat-header-cell *matHeaderCellDef> MERCHANT NAME</th>
          <td mat-cell *matCellDef="let element"> {{element.merchant_info}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="item_amount">
          <th mat-header-cell *matHeaderCellDef> TRANS AMOUNT</th>
          <td mat-cell *matCellDef="let element"> {{element.item_amount}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef> COUNTRY</th>
          <td mat-cell *matCellDef="let element"> {{element.country}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="ind" hidden="true">
          <th hidden="true" mat-header-cell *matHeaderCellDef> CARD PRESENT</th>
          <td hidden="true" mat-cell *matCellDef="let element"> {{element.ind}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="tran_type" hidden="true">
          <th hidden="true" mat-header-cell *matHeaderCellDef> TRANS TYPE</th>
          <td hidden="true" mat-cell *matCellDef="let element"> {{element.tran_type}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="currency" hidden="true">
          <th hidden="true" mat-header-cell *matHeaderCellDef> CURRENCY</th>
          <td hidden="true" mat-cell *matCellDef="let element"> {{element.currency}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
      </table>

      <mat-toolbar>
        <mat-toolbar-row class="toolBarRow">
          <div class="DownloadText">DOWNLOAD </div>
          <img class="icon" src="../../../assets/PDFFileIcon.png" (click)="exportTable();
  dtm.clickEvent('informational', 'transaction results', 'corporate card dashboard | transactions | transactions pdf download pdf icon click')">
          <img class="icon" src="../../../assets/CSVFileIcon.png" (click)="exportCSVTable();
  dtm.clickEvent('informational', 'transaction results', 'corporate card dashboard | transactions | transactions csv download csv icon click')">
          <mat-paginator class="tablePagenator" [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </mat-card-content>
</mat-card>
