export class Card {
  Name: string;
  Account: string;
  Type: string;
  AvailableBalance: number;
  LatestBalance: number;
  CreditLimit: number;
  Email: string;
  ContactNumber: string;
  MobileNumber: string;
  TravelNotification: boolean;
  CardExpiry: string;
  TravelDateStart: string;
  TravelDateEnd: string;
  Status: string;
  AllocatedTo?: string;
  AllocateAmount?: string;
  LimitIncrDecAmount?: string;
  MaskedCardNumber?: string;
  flip?: boolean;
}
