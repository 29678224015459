<div class="dialogBox">
  <div class="title">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>
  <div mat-dialog-content>
    <p class="text">{{ data.message }}</p>
  </div>
  <div mat-dialog-actions>
    <button id="submit" type="button" mat-button onclick="logout()">OK</button>
  </div>
</div>
