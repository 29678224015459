<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Distinguishing Between Roll Up and Non-Roll Up</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <div class="col-9 w-100">
      <div class="container pr-4">
        <div class="whiteBlock">
          <div class="blockHeadings">Roll up:</div>
          <div class="row">
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/CardsIcon.png">
              <div class="iconText">All the cards are issued under the same
                company profile.
              </div>
            </div>
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/MoneyBagIcon.png">
              <div class="iconText">Each card balance will roll up to a
                consolidated account.
              </div>
            </div>
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/CoinStackIcon.png">
              <div class="iconText">The company is required to make one full
                settlement for all the cards.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/PenAndCardIcon.png">
              <div class="iconText">The company settles all the outstanding balances on behalf of the cardholders.</div>
            </div>
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/CoinProfileRotationIcon.png">
              <div class="iconText">The company is ultimately responsible
                for the settlement of all cards should the
                cardholder default.
              </div>
            </div>
          </div>
        </div>
        <div class="greyBlock">
          <div class="blockHeadings">Non-Roll up:</div>
          <div class="row">
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/CardsIcon.png">
              <div class="iconText">All the cards are issued under the same
                company profile.
              </div>
            </div>
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/CardAndCashIcon.png">
              <div class="iconText">Each card is treated separately and settled
                individually.
              </div>
            </div>
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/ProfileIcon.png">
              <div class="iconText">Each cardholder settles his/her own card
                balance from his/her personal bank account. A debit order will be
                mandatory.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/CardInformationIcon.png">
              <div class="iconText">Alternatively, the company can settle each card separately.</div>
            </div>
            <div class="col-4 imageAndTextBlock">
              <img src="../../../assets/Icons/CoinProfileRotationIcon.png">
              <div class="iconText">The company is ultimately responsible
                for the settlement of all cards should the
                cardholder default.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
