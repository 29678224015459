import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Company} from '../_models/';
import {CompanySummary} from '../_models/companySummary';
import {Email} from '../_models/email';
import {Transactions} from '../_models/transaction';
import {User} from '../_models/user';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';
import { CookieOptions, CookieService } from 'ngx-cookie';

const LS_TOKEN_AUTH_SIZE = 'authentication_size';
const LS_TOKEN_AUTH0    = 'authentication0';
const LS_TOKEN_AUTH1    = 'authentication1';
const LS_TOKEN_AUTH2    = 'authentication2';
const LS_TOKEN_AUTH3    = 'authentication3';
const LS_TOKEN_AUTH4    = 'authentication4';
const LS_TOKEN_AUTH5    = 'authentication5';
const LS_TOKEN_AUTH6    = 'authentication6';
const LS_TOKEN_AUTH7    = 'authentication7';
const LS_TOKEN_AUTH8    = 'authentication8';
const LS_TOKEN_AUTH9    = 'authentication9';
const options: CookieOptions = {
  httpOnly: false,
  secure: true,
  sameSite: "strict"
};

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  public sendEmailURL = '';

  public authEndpointURL;
  public getCompanyListURL;
  public getSecureListURL;
  public getCompanySummaryURL;
  public getTransactionListURL;
  public cancelCardURL;
  public limitTransferURL;
  public getLimitIncreaseURL;
  public getNotificationsURL;

  constructor(private http: HttpClient, private dtm: DtmAnalyticsServiceService,
    private cookieService: CookieService) {
    this.authEndpointURL = '';
    this.getCompanyListURL = '';
    this.getSecureListURL = '';
    this.getCompanySummaryURL = '';
    this.getTransactionListURL = '';
    this.cancelCardURL = '';
    this.limitTransferURL = '';
    this.getLimitIncreaseURL = '';
    this.getNotificationsURL = '';
    this.sendEmailURL = '';
  }

  login(id: string, companyReg: string, companyName: string): Observable<User>{
    return this.http.post<User>(this.authEndpointURL + '/rest/login',
      `{"id": "${id}", "companyreg": "${companyReg}", "companyname": "${companyName}"}`, {observe: 'response', reportProgress: true,
      withCredentials: true})
      .pipe(map(resp => {
        // console.log("login - about getTokenAuth");
        if (this.getTokenAuth() === '') {
          // console.log("login:", this.getTokenAuth());
          // console.log("login about to write:", resp.headers.get('X-Session-Token'))
          this.putTokenAuth(resp.headers.get('X-Session-Token'));
        }
        catchError(this.handleError);
        return resp.body;
      }));
  }

  tokenRefresh() {
    return this.http.get(this.authEndpointURL + '/rest/cc-refresh', {observe: 'response', withCredentials: true})
      .pipe(map(response => {
        this.putTokenAuth(response.headers.get('X-Session-Token'));
        // console.log("tokenRefresh:", response.headers.get('X-Session-Token'));
        catchError(this.handleError);
        }
      ));
  }

  getCompanyList(): Observable<Company> {
    return this.http.get<Company>(this.getCompanyListURL + `/rest/bpidlist`, {observe: 'response', withCredentials: true})
      .pipe(map(response => {
        this.putTokenAuth(response.headers.get('X-Session-Token'));
        // console.log("getCompanyList:", response.headers.get('X-Session-Token'));
        catchError(this.handleError);
        return response.body;
        }
      ));
  }

  getSecureList(companyID: string, companySubLevelNo: string, companySubLevelId: string) {
    return this.http.get(this.getSecureListURL + '/rest/securelist?company_id=' + companyID + '&company_sublevel_no=' + companySubLevelNo + '&company_sublevel_id=' + companySubLevelId,
    {observe: 'response', withCredentials: true})
      .pipe(map(response => {
        this.putTokenAuth(response.headers.get('X-Session-Token'));
        // console.log("getSecureList:", response.headers.get('X-Session-Token'));
        sessionStorage.setItem('logged-in', 'yes');
        catchError(this.handleError);
        }
      ));
  }

  getCompanySummary(): Observable<CompanySummary> {
    return this.http.get<CompanySummary>(this.getCompanySummaryURL + `/rest/summary`, {observe: 'response', withCredentials: true})
      .pipe(map(response => {
          this.putTokenAuth(response.headers.get('X-Session-Token'));
          // console.log("getCompanySummary:", response.headers.get('X-Session-Token'));
          catchError(this.handleError);
          return response.body;
        }
      ));
  }

  getTransactionList(cardNumber: string, fromDate: string, toDate: string): Observable<Transactions> {
    return this.http.post<Transactions>(this.getTransactionListURL + `/rest/transaction`, `{
    "panNumber":"${cardNumber}",
    "startDate":"${fromDate}",
    "endDate":"${toDate}"}`, {observe: 'response', withCredentials: true})
      .pipe(map(response => {
          catchError(this.handleError);
          return response.body;
        }
      ));
  }

  cancelCard(cardNumber: string): Observable<CompanySummary> {
    return this.http.post<CompanySummary>(this.cancelCardURL + `/rest/cancelcard`, `{"panNumber": ["${cardNumber}"]}`,
    {observe: 'response', withCredentials: true})
      .pipe(map(response => {
          catchError(this.handleError);
          return response.body;
        }
      ));
  }

  limitTransfer(sourceCard: string, sourceAmount: string, destinationCard: string, destinationAmount: string) {
    return this.http.post(this.limitTransferURL + `/rest/limitupdate`, `{"CardLimit": [{
      "card":"${sourceCard}",
      "limit":"${sourceAmount}"}, {
      "card":"${destinationCard}",
      "limit":"${destinationAmount}"}
    ]}`, {observe: 'response', withCredentials: true})
      .pipe(map(response => {
          catchError(this.handleError);
          return response.body;
        }
      ));
  }

  limitIncrease(sourceCard: string, amount: string) {
    return this.http.post(this.getLimitIncreaseURL + `/rest/limitupdate`, `{"CardLimit": [{
      "card":"${sourceCard}",
      "limit":"${amount}"}
    ]}`, {observe: 'response', withCredentials: true})
      .pipe(map(response => {
          catchError(this.handleError);
          return response.body;
        }
      ));
  }

  getNotifications(): Observable<CompanySummary> {
    return this.http.get<CompanySummary>(this.getNotificationsURL + `/rest/notifications`, {observe: 'response', withCredentials: true})
      .pipe(map(response => {
          catchError(this.handleError);
          return response.body;
        }
      ));
  }

  sendEmail(email: Email): Observable<Email> {
    return this.http.post<Email>( this.sendEmailURL + `/rest/email`, email, {withCredentials: true})
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // console.log("http error: "+errorMessage);
    this.dtm.sendSiteError(error.status, error.url, errorMessage);
    return throwError(errorMessage);
  }

  getTokenAuth() {
    var size;
    var compressed = '';
    if (this.cookieService.get(LS_TOKEN_AUTH_SIZE)) {
      size = Number(this.cookieService.get(LS_TOKEN_AUTH_SIZE));
      for (var i=0,j=0; i < size; i+=4000,j++) {
        switch(j) {
          case 0:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH0)
            break;
          case 1:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH1)
            break;
          case 2:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH2)
            break;
          case 3:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH3)
            break;
          case 4:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH4)
            break;
          case 5:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH5)
            break;
          case 6:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH6)
            break;
          case 7:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH7)
            break;
          case 8:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH8)
            break;
          case 9:
            compressed = compressed + this.cookieService.get(LS_TOKEN_AUTH9)
            break;                                                                                  }
      }
    }
    // const decompressed = this.lz.decompress(compressed);
    const decompressed = compressed;
    // console.log("getTokenAuth:", strToken);
    return decompressed;
  }

  putTokenAuth(tokenStr) {
    // const compressed = this.lz.compress(tokenStr);
    const compressed = tokenStr;
    this.cookieService.put(LS_TOKEN_AUTH_SIZE, compressed.length.toString(), options);
    for (var i=0,j=0; i < compressed.length; i+=4000,j++) {
      var end;
      if (compressed.length-i < 4000) {
        end = compressed.length-i;
      } else {
        end = 4000;
      }
      switch(j) {
        case 0:
          this.cookieService.put(LS_TOKEN_AUTH0, compressed.substr(i,end), options);
          break;
        case 1:
          this.cookieService.put(LS_TOKEN_AUTH1, compressed.substr(i,end), options);
          break;
        case 2:
          this.cookieService.put(LS_TOKEN_AUTH2, compressed.substr(i,end), options);
          break;
        case 3:
          this.cookieService.put(LS_TOKEN_AUTH3, compressed.substr(i,end), options);
          break;
        case 4:
          this.cookieService.put(LS_TOKEN_AUTH4, compressed.substr(i,end), options);
          break;
        case 5:
          this.cookieService.put(LS_TOKEN_AUTH5, compressed.substr(i,end), options);
          break;
        case 6:
          this.cookieService.put(LS_TOKEN_AUTH6, compressed.substr(i,end), options);
          break;
        case 7:
          this.cookieService.put(LS_TOKEN_AUTH7, compressed.substr(i,end), options);
          break;
        case 8:
          this.cookieService.put(LS_TOKEN_AUTH8, compressed.substr(i,end), options);
          break;
        case 9:
          this.cookieService.put(LS_TOKEN_AUTH9, compressed.substr(i,end), options);
          break;
      }
    }
  }
}
