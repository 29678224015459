<mat-card class="h-75">
  <mat-card-header>
    <mat-card-title>DID YOU KNOW?</mat-card-title>
  </mat-card-header>
  <mat-card-content>
      <ngb-carousel class="">
        <ng-template ngbSlide>
          <div class="wrapper">
            <div>You can view the more information on the Corporate Product Offering</div>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="wrapper">
            <div>You can view transactional details per card</div>
          </div>
        </ng-template>
        <!-- <ng-template ngbSlide>
          <div class="wrapper">
            <div>You can cancel a card</div>
          </div>
        </ng-template> -->
        <hr>
      </ngb-carousel>
  </mat-card-content>
</mat-card>

