export class TableUtil {
  static exportToPdf(transactions: any[], name?: string) {
    let printContents, popupWin;
    var pdfHtml = `
    <thead role="rowgroup">
      <tr role="row" mat-header-row="" class="mat-header-row cdk-header-row ng-star-inserted">
          <th role="columnheader" mat-header-cell="" class="mat-header-cell cdk-header-cell cdk-column-date mat-column-date ng-star-inserted"> TRANS DATE</th>
          <th role="columnheader" mat-header-cell="" class="mat-header-cell cdk-header-cell cdk-column-merchant_info mat-column-merchant_info ng-star-inserted"> MERCHANT NAME</th>
          <th role="columnheader" mat-header-cell="" class="mat-header-cell cdk-header-cell cdk-column-item_amount mat-column-item_amount ng-star-inserted"> TRANS AMOUNT</th>
          <th role="columnheader" mat-header-cell="" class="mat-header-cell cdk-header-cell cdk-column-country mat-column-country ng-star-inserted"> COUNTRY</th>
      </tr>
    </thead>
    <tbody role="rowgroup">
    `
    transactions.forEach( (element) => {
      pdfHtml = pdfHtml +
      `  <tr role="row" mat-row="" class="mat-row cdk-row ng-star-inserted" style="">
           <td role="gridcell" mat-cell="" class="mat-cell cdk-cell cdk-column-date mat-column-date ng-star-inserted"> `+element.date+`</td>
           <td role="gridcell" mat-cell="" class="mat-cell cdk-cell cdk-column-merchant_info mat-column-merchant_info ng-star-inserted"> `+element.merchant_info+`</td>
           <td role="gridcell" mat-cell="" class="mat-cell cdk-cell cdk-column-item_amount mat-column-item_amount ng-star-inserted"> `+element.item_amount+`</td>
           <td role="gridcell" mat-cell="" class="mat-cell cdk-cell cdk-column-country mat-column-country ng-star-inserted"> `+element.country+`</td>
         </tr>
         `;
    });
    pdfHtml = pdfHtml + `</tbody>`;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
  <html>
    <head>
      <title>Print tab</title>

    </head>
<body onload="window.print();window.close()"><table class="table table-bordered">${pdfHtml}</table></body>
  </html>`
    );
    popupWin.document.close();
  }
}
