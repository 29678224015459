import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Card} from '../../../_models/card';
import {CompanySummary} from '../../../_models/companySummary';
import {DataStorageService} from '../../../_services/data-storage.service';
import {RestApiService} from '../../../_services/rest-client-service';
import {MaskedCard} from '../../../_models/maskedCard';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {DtmAnalyticsServiceService} from '../../../dtm-analytics-service.service';

@Component({
  selector: 'app-dialog-box-endpoint',
  templateUrl: './dialog-box-cancel.component.html',
  styleUrls: ['./dialog-box-cancel.component.css']
})
export class DialogBoxCancelComponent implements OnInit {
  summary: CompanySummary;
  action: string;
  localData: any
  amount: string;
  cardNumber: string;
  currentLimit: string;
  maskedNumber:string;
  dtmAction: string;
  newAmountNumber: number;
  newAmount: string;
  amountNumber: number;
  review_btn: boolean;
  BillingAccount: string;
  responseData: any;
  error = '';
  allowCancel: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogBoxCancelComponent>, private dataStore: DataStorageService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Card, private restApi: RestApiService, public dtm: DtmAnalyticsServiceService
  ) {
    this.localData = {...data};
    this.action = this.localData.action;
    this.amount = this.localData.amount;
    this.amountNumber = Number(this.amount)
    this.cardNumber = this.localData.Account;
    this.currentLimit = this.localData.CreditLimit;
    this.newAmountNumber = Number(this.localData.CreditLimit) - Number(this.localData.amount);
    this.newAmount = this.newAmountNumber.toString()
    this.maskedNumber = this.localData.MaskedCardNumber;
    if (this.amountNumber == 0) {
      this.review_btn = false;
    } else {
      this.review_btn = true;
    }
  }

  ngOnInit() {
    this.summary = this.dataStore.getCompanySummary();
    if (this.summary === undefined) {
      this.getCompanySummary();
    }
    this.BillingAccount = this.summary.BillingAccount;
    console.log("Billing account:"+this.BillingAccount);
    if (this.BillingAccount.length === 16) {
      // console.log("equal 16");
      this.allowCancel = true;
    } else {
      if (this.amountNumber !== 0) {
        // console.log("not equal 0");
        this.allowCancel = false;
      } else {
        // console.log("all the rest");
        this.allowCancel = true;
      }
    }
  }

  doAction() {
    this.dtm.clickEvent('informational', this.dtmAction, 'corporate card dashboard | card management | submitt searched issued card '+this.dtmAction+' | yes button click')
    this.dialogRef.close({event: this.action, data: this.localData});
  }

  closeDialog() {
    this.dtm.clickEvent('informational', this.dtmAction, 'corporate card dashboard | card management | cancel searched issued card '+this.dtmAction+' | no button click')
    this.dialogRef.close({event: 'CANCEL'});
  }

  getCompanySummary() {
    return this.restApi.getCompanySummary().subscribe((data: {}) => {
        this.responseData = data;
        this.summary = this.responseData;
      },
      error => {
        this.error = error;
      });
  }

}
