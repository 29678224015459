export class Transactions {
  transactions: Transaction[];
}

export class Transaction {
  country: string;
  currency: string;
  date: string;
  ind: string;
  item_amount: string;
  merchant_info: string;
  number: string;
  pos_entry: string;
  response: string;
  time: string;
  tran_type: string;
}
