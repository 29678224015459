import { Component, OnInit, Input } from '@angular/core';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';
import { AuthorizationService } from '../authorization.service';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { environmentProd } from 'src/environments/environment.prod';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() currentUser = '';

  constructor(private router: Router, public dtm: DtmAnalyticsServiceService,
              public authorizationService: AuthorizationService) { }

  ngOnInit(): void {
  }

  logout(): void {
    // console.log('Logging out');
    sessionStorage.clear();
    this.authorizationService.signOut();
    this.router.navigateByUrl('/');
    if (document.location.hostname === environment.prod_url) {
      let redirect = environmentProd.redirect_uri;
      let redirect_url = redirect.replace("/callback", "");
      document.location.href = environmentProd.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environmentProd.client_id + `&TargetResource=` + redirect_url
    } else {
      let redirect = environment.redirect_uri;
      let redirect_url = redirect.replace("/callback", "");
      document.location.href = environment.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environment.client_id + `&TargetResource=` + redirect_url
    }
  }
}
