import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Card} from '../../../_models/card';
import {DtmAnalyticsServiceService} from '../../../dtm-analytics-service.service';

@Component({
  selector: 'app-dialog-box-endpoint',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.css']
})
export class DialogBoxComponent implements OnInit {
  action: string;
  localData: any
  amount: string;
  cardNumber: string;
  currentLimit: string;
  maskedNumber:string;
  dtmAction: string;
  newAmountNumber: number;
  newAmount: string;

  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Card, public dtm: DtmAnalyticsServiceService
  ) {
    this.localData = {...data};
    this.action = this.localData.action;
    this.amount = this.localData.allocatedAmount;
    this.cardNumber = this.localData.Account;
    this.currentLimit = this.localData.CreditLimit;
    if (this.localData.cardLimitAmount === undefined) {
      this.newAmountNumber = Number(this.localData.CreditLimit) - Number(this.localData.allocatedAmount)
    } else {
      if (this.localData.allocatedAmount === undefined) {
        this.newAmountNumber = Number(this.localData.CreditLimit) + Number(this.localData.cardLimitAmount);
      } else {
        this.newAmountNumber = Number(this.localData.CreditLimit) - Number(this.localData.allocatedAmount) + Number(this.localData.cardLimitAmount);
      }
    }
    this.newAmount = this.newAmountNumber.toString()
    this.maskedNumber = this.localData.MaskedCardNumber;
    this.dtmAction = 'limit allovation';
  }

  ngOnInit() {
  }

  doAction() {
    this.dtm.clickEvent('informational', this.dtmAction, 'corporate card dashboard | card management | submitt searched issued card '+this.dtmAction+' | yes button click')
    this.dialogRef.close({event: this.action, data: this.localData});
  }

  closeDialog() {
    this.dtm.clickEvent('informational', this.dtmAction, 'corporate card dashboard | card management | cancel searched issued card '+this.dtmAction+' | no button click')
    this.dialogRef.close({event: 'CANCEL', data: this.localData});
  }

}
