import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-garage-card',
  templateUrl: './garage-card.component.html',
  styleUrls: ['./garage-card.component.css']
})
export class GarageCardComponent implements OnInit {
  currentUser: string;

  constructor() {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  ngOnInit(): void {
  }

}
