import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roll-up-non-roll-up',
  templateUrl: './roll-up-non-roll-up.component.html',
  styleUrls: ['./roll-up-non-roll-up.component.css']
})
export class RollUpNonRollUpComponent implements OnInit {
  currentUser: string;

  constructor() {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  ngOnInit(): void {
  }

}
