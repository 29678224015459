<div class="Rectangle">
  <div class="SearchByHeading">Search By:</div>
  <mat-spinner [diameter]="70" class="daSpina" *ngIf="loadingCardList"></mat-spinner>
  <form *ngIf="!loadingCardList" [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
    <mat-form-field class="spendDropDown">
      <mat-label>Name On Card</mat-label>
      <mat-select (selectionChange)="getAssociatedCardNumbers($event)" formControlName="name"
                  (click)="dtm.clickEvent('informational', 'cardholder selector', 'corporate card dashboard | manage cardholders | name on card dropdown click')">
        <mat-option *ngFor="let name of names" [value]="name">{{ name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="spendDropDown">
      <mat-label>Select Card Number</mat-label>
      <mat-select formControlName="cardNumber"
                  (click)="dtm.clickEvent('informational', 'cardholder selector', 'corporate card dashboard | manage cardholders | card number dropdown click')">
        <mat-option *ngFor="let cardNumber of associatedCards" [value]="cardNumber.cardNo">{{ cardNumber.maskedCardNo }}</mat-option>
      </mat-select>
    </mat-form-field>

      <button class="ResetButton" (click)="resetValues();
dtm.clickEvent('informational', 'manage cardholders', 'corporate card dashboard | manage cardholders | which user to display reset button click')"
      >RESET</button>
      <button [disabled]="!formGroup.valid" class="SubmitButton" type="submit"
              (click)="dtm.clickEvent('informational', 'manage cardholders', 'corporate card dashboard | manage cardholders | which user to display button click')"
      >DISPLAY</button>
  </form>
</div>

<mat-card class="ListCard">
  <mat-card-header class="TransactionsHeading col-3">Users
  </mat-card-header>
  <div  class="col-3 toggle mb-2">ACTIVE<mat-slide-toggle class="pl-3" (change)="filterCardView($event);
        dtm.clickEvent('informational', 'cardholder selector', 'corporate card dashboard | manage cardholders | active inactive cards toggle click')"
                                                          color="primary">INACTIVE</mat-slide-toggle></div>

  <div class="mat-elevation-z8">
    <table id="myTable" mat-table [dataSource]="dataSource" class="my-table mat-elevation-z8">

      <ng-container matColumnDef="id">
        <th hidden="true" mat-header-cell *matHeaderCellDef> ID</th>
        <td hidden="true" mat-cell *matCellDef="let element"> {{element.id}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="cardHolder">
        <th mat-header-cell *matHeaderCellDef> CARD HOLDER NAME</th>
        <td mat-cell *matCellDef="let element"> {{element.Name}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="cardNumber">
        <th mat-header-cell *matHeaderCellDef> CARD NUMBER</th>
        <td mat-cell *matCellDef="let element"> {{element.MaskedCardNumber}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="mobileNumber">
        <th mat-header-cell *matHeaderCellDef> CONTACT NUMBER</th>
        <td mat-cell *matCellDef="let element"> {{element.ContactNumber}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef> EMAIL ADDRESS</th>
        <td mat-cell *matCellDef="let element"> {{element.Email}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="creditLimit">
        <th mat-header-cell *matHeaderCellDef> CREDIT LIMIT</th>
        <td mat-cell *matCellDef="let element"> {{element.CreditLimit}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="availableAmount">
        <th mat-header-cell *matHeaderCellDef> AVAILABLE AMOUNT</th>
        <td mat-cell *matCellDef="let element"> {{element.AvailableBalance}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
    </table>

    <mat-toolbar>
      <mat-toolbar-row class="toolBarRow">
        <div class="DownloadText">DOWNLOAD</div>
        <img class="icon" src="../../../assets/PDFFileIcon.png" (click)="exportTable();
dtm.clickEvent('informational', 'user results', 'corporate card dashboard | manage cardholders | user pdf download icon click')">
        <img class="icon" src="../../../assets/CSVFileIcon.png" (click)="exportCSVTable();
dtm.clickEvent('informational', 'user results', 'corporate card dashboard | manage cardholders | user csv download icon click')">
        <mat-paginator class="tablePagenator" [pageSizeOptions]="[5, 10, 20]"
                       showFirstLastButtons></mat-paginator>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</mat-card>
