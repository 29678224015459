import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';
import { UserInfo } from '../userinfo';
import { TokenResponse } from '@openid/appauth';
import { AuthorizationService } from '../authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public userInfo: UserInfo | null;
  public authorized: boolean;
  checkLogin = 1;

  constructor(private router: Router, public dtm: DtmAnalyticsServiceService,
              public authorizationService: AuthorizationService) {
    this.userInfo = null;
    this.authorized = false;
  }

  ngOnInit(): void {
    // console.log('constructor for login!');
    this.authorizationService.userInfos().subscribe((userInfo: UserInfo | null) => {
      // console.log('Set userInfo login');
      this.userInfo = userInfo;
    });
    if (this.checkLogin === 1 && this.userInfo !== null) {
      this.dtm.updateDataLayerLogout();
    }
    setTimeout(() => {
      this.authorizationService.tokenResponse().subscribe((tokenResponse: TokenResponse | null) => {
        // console.log('Set authorized login');
        this.authorized = tokenResponse != null;
      });
      if (this.authorized === false) {
        // console.log('authorized === false login');
        this.checkLogin = 3;
        this.ngOnInit();
      } else {
        // this.router.navigateByUrl('/id');
        this.router.navigateByUrl('/home');
      }
    }, 1000);
  }
}
