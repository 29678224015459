<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <div class="TransactionsHeading">Transaction Details</div>
  <div class="row">
      <div class="col">
        <mat-tab-group dynamicHeight class=" tabgroup w-100">
          <mat-tab label="TRANSACTION DETAILS">
          <app-transaction-results></app-transaction-results>
          </mat-tab>
          <!--<mat-tab label="STATEMENTS">-->
          <!--</mat-tab>-->
        </mat-tab-group>
      </div>
  </div>
</div>
<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
