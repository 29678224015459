import { Component, OnInit } from '@angular/core';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  currentUser: string;

  constructor(private dtm: DtmAnalyticsServiceService) {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  ngOnInit(): void {
    this.dtm.updatePageProperties('business:corporate card dashboard:Help');
    this.dtm.updateDataLayerForPage();
  }

}
