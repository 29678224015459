import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NotificationComponent} from '../notification/notification.component';
import {AuthorizationService} from '../authorization.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpResponseInterceptor implements HttpResponseInterceptor {

  constructor(private snackBar: MatSnackBar, public dialog: MatDialog,
              public authorizationService: AuthorizationService,
              private router: Router) {
  }

  dialogRef: MatDialogRef<NotificationComponent>;
  stringifyData: any;
  paresedJson: any;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (evt.url.includes('/rest/email') && evt.status === 200) {
            return this.openSuccessSnackBar('Email sent successfully');
          }
          if (evt.url.includes('/rest/transaction') && evt.status === 200 && !evt.body.transactions) {
            return this.openSuccessSnackBar('No transactions found');
          }
          if (evt.url.includes('/rest/limitupdate') && evt.status === 200) {
            return this.openSuccessSnackBar('Limit update successful');
          }
          if (evt.url.includes('/rest/cancelcard') && evt.status === 200) {
            return this.openSuccessSnackBar('Card cancelled successfully.');
          }
          if (evt.url.includes('/rest/notifications') && evt.status === 200 && evt.body === '[]') {
            // console.log('entering notification response');
            return this.openSuccessSnackBar('No notifications found for this company');
          }
        }
      }),
      catchError((err: any) => {
        if (err.status === 401 && JSON.stringify(err.error).includes('Token is expired')) {
          return throwError(this.sessionExpiredNotification());
        }
        if (err.status === 200 && err.url.includes('/rest/notifications')) {
          return throwError(console.log('No Notifications found'));
        } else {
          if (err.status === 400 && err.url.includes('/rest/limitupdate')) {
            if (err.error.includes('lmt_amd_response_msg')) {
              var i = 0
              var start = 0;
              var end = 0;
              for (; i < err.error.length-20; i++) {
                if (err.error.substring(i,i+20) === "lmt_amd_response_msg") {
                  start = i + 23
                  i = start
                  break;
                }
              }
              for (; i < err.error.length-20; i++) {
                if (err.error.substring(i,i+7) === "\"}}}\"}}") {
                  end = i
                  break;
                }
              }
              return throwError(this.openFailureSnackBar(err.error.substring(start, end)));
            } else {
              return throwError(this.openFailureSnackBar('Technical error'));
            }
          } else {
          if (err.url.includes('/rest/login')) {
            sessionStorage.clear();
            this.authorizationService.signOut();
            this.router.navigateByUrl('/');
            return throwError(this.openFailureSnackBar('Technical error'));
          } else {
            if (err.status === 400 && err.url.includes('/rest/cancelcard')) {
              this.stringifyData = JSON.stringify(err.error);
              this.paresedJson = JSON.parse(this.stringifyData)
              console.log("paresedJson:", this.paresedJson);
              if (this.paresedJson.corporatecardbackend.faultstring) {
                return throwError(this.openFailureSnackBar(this.paresedJson.corporatecardbackend.faultstring));
              } else {
                return throwError(this.openFailureSnackBar('Technical error'));
              }
            } else {
              return throwError(this.openFailureSnackBar('Technical error'));
            }
          }
        }
      }
      }));
  }

  openSuccessSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 6000,
      panelClass: ['green-snackbar', 'login-snackbar'],
      verticalPosition: 'top',
    });
  }

  openFailureSnackBar(message: string) {
    this.snackBar.open(message, 'Dismiss', {
      duration: 15000,
      panelClass: ['red-snackbar', 'login-snackbar'],
      verticalPosition: 'top',
    });
  }

  public sessionExpiredNotification() {
    this.dialogRef = this.dialog.open(NotificationComponent, {
      data: {
        duration: 100000,
        title: 'Session Expired',
        message: 'Your sessions has expired. You will be redirected to login',
      }
    });
  }
}
