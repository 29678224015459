import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';

@Component({
  selector: 'app-manage-cardholders',
  templateUrl: './manage-cardholders.component.html',
  styleUrls: ['./manage-cardholders.component.css']
})

export class ManageCardholdersComponent implements OnInit {
  currentUser: string;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private dtm: DtmAnalyticsServiceService) {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

    ngOnInit(){
      this.dtm.updatePageProperties('business:corporate card dashboard:manage cardholders');
      this.dtm.updateDataLayerForPage();
    }
}
