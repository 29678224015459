<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Garage Card</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <mat-tab-group dynamicHeight class="col-9 w-100">
      <mat-tab label="GARAGE CARD">
        <div class="row pt-5">
          <img class="col-4 w-100 h-100" src="../../../assets/manOnPhone.png" alt="Image of man on phone">
          <div class="col-4">
            <h4 class="SubHeading">Garage Card</h4>
            <div class="TextContent">
              <p>
                The Garage Card is a convenient payment option for frequent road travelling. It covers
                the business’s fuel, repairs, toll fees, maintenance, spares and other vehicle-related
                expenses.
              </p>
            </div>
          </div>
          <div class="col-4">
            <img class="w-75" src="../../../assets/garageCard.png" alt="Image of garage card">
            <button mat-raised-button color="primary" hidden>APPLY NOW</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
