<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <div class="row">
    <div class="col-lg-6 col-xs-12">
      <app-help-contact-info></app-help-contact-info>
    </div>
    <div class="col-lg-6 col-xs-12">
      <app-log-support-query></app-log-support-query>
    </div>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>

