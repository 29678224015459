<div class="header">
  <app-version hidden></app-version>
  <img id="sbglogo" src="../assets/standardbank-logo.png">
  <div>
    <img class="userIcon" src="../assets/userIcon.png" height="35" width="35"/>
    <div class="name">{{currentUser}}</div>
  </div>
  <img id="signOut" src="../assets/signOut.png" onclick="" (click)="
  dtm.clickEvent('confirmational', 'header nav', 'sign out button click');logout()">
</div>
<div class="row">
  <nav class="navbar navbar-expand-md navbar-light">
    <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2 pl-4">
      <ul class="navbar-nav mr-auto">
        <img src="../assets/homeIcon.png" height="35" width="35"/>
        <li class="nav-item active">
          <a class="nav-item nav-link" [routerLinkActive]="['navActive']" routerLink="/home"
          (click)="dtm.clickEvent('navigational', 'header nav', 'corporate card dashboard tab link click')">
            CORPORATE CARD DASHBOARD</a>
        </li>
      </ul>
    </div>

    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item pr-4 active">
          <a class="nav-item nav-link" [routerLinkActive]="['navActive']" routerLink="/statements"
            (click)="dtm.clickEvent('navigational', 'header nav', 'corporate card dashboard | transactions tab link click')">
            TRANSACTIONS</a>
        </li>
        <li class="nav-item pr-4 active">
          <a class="nav-item nav-link" [routerLinkActive]="['navActive']" routerLink="/managecardholders"
            (click)="dtm.clickEvent('navigational', 'header nav', 'corporate card dashboard | manage cardholders tab link click')">
            MANAGE CARDHOLDERS</a>
        </li>
        <li class="nav-item pr-4 active">
          <a class="nav-item nav-link" [routerLinkActive]="['navActive']" routerLink="/productoffering"
            (click)="dtm.clickEvent('navigational', 'header nav', 'corporate card dashboard | product offerings tab link click')">
            PRODUCT OFFERING</a>
        </li>
        <li class="nav-item pr-4 active">
          <a
            class="nav-item nav-link" [routerLinkActive]="['navActive']" routerLink="/help"
            (click)="dtm.clickEvent('navigational', 'header nav', 'corporate card dashboard | help tab link click')">
            HELP</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<router-outlet></router-outlet>
