import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {Company} from '../../_models';
import {CompanySummary} from '../../_models/companySummary';
import {RestApiService} from '../../_services/rest-client-service';
import {Card} from '../../_models/card';
import {Color} from 'ng2-charts';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChartOptions, ChartType, ChartDataSets} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {MaskedCard} from '../../_models/maskedCard';
import {Notification} from '../../_models/notification';
import {DataStorageService} from '../../_services/data-storage.service';
import {DtmAnalyticsServiceService} from '../../dtm-analytics-service.service';


export class Companies {
  CompanyList: Company[];
}


@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.css']
})
export class AccountManagementComponent implements OnInit {
  @Output() redirect: EventEmitter<any> = new EventEmitter();
  companies: Company[];
  cards: Card[];
  cardsCombo: {card: Card[]; summary: CompanySummary};
  notifications: Notification[];
  currentUser: string;
  responseData: any = [];
  bpListResponse: Companies;
  headers = new HttpHeaders();
  error = '';
  selectedCompany: any;
  selectedCompanyName = '';
  loadingCompanyList = false;
  loadingAccountList = false;
  loadingNotificationList = false;
  summary: CompanySummary = new CompanySummary();
  cardHolderList: string[] = [];
  associatedCards: MaskedCard[] = [];
  cardNumbers: MaskedCard[] = [];
  analysisTypes: string[] = ['Available Balance'];
  selectedCardLimit: number = 0;
  selectedCardAvailable: number = 0;
  notificationsRetrieved = false;

  public barChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'start',
        color: 'white',
        font: {
          size: 20,
        }
      }
    },
    tooltips: {
      enabled: false
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        stacked: false
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        stacked: false
      }]
    },
  };
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];
  public barChartColors: Color[] = [
    {backgroundColor: '#3366cc'},
    {backgroundColor: '#00a1af'},
  ];

  public barChartData: ChartDataSets[] = [
    {data: [this.selectedCardLimit]},
    {data: [this.selectedCardAvailable]}
  ];

  formGroup: FormGroup = new FormGroup({});


  constructor(private restApi: RestApiService, private formBuilder: FormBuilder, private dataStore: DataStorageService,
              public dtm: DtmAnalyticsServiceService) {
    this.currentUser = sessionStorage.getItem('currentUser');
    this.loadingAccountList = true;
    this.loadingCompanyList = true;
    this.loadingNotificationList = true;
    this.formGroup = this.formBuilder.group({
      cardHolder: ['', [Validators.required]],
      cardNumber: ['', Validators.required],
      analysisType: ['', [Validators.required]],
    });
  }

  getCompanyList() {
    return this.restApi.getCompanyList().subscribe((data: {}) => {
        this.responseData = data;
        this.bpListResponse = this.responseData;
        this.companies = this.bpListResponse.CompanyList;
        if (this.selectedCompany === undefined) {
          this.selectedCompany = this.companies[0];
          this.getSecureList(this.companies[0].CompanyName, this.companies[0].CompanyId, this.companies[0].CompanySublevelNo, this.companies[0].CompanySublevelId);
        }
      },
      error => {
        this.error = error;
        this.loadingCompanyList = false;
        this.loadingNotificationList = false;
      });
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (sessionStorage.getItem('currentUser') && !this.companies) {
        this.getCompanyList();
      } else {
        this.ngOnInit();
      }
    }, 1000);
  }

  getSecureList(companyName: string, companyID: string, companySubLevelNo: string, companySubLevelId: string) {
    var anchors = document.getElementsByTagName("body");
    document.body.style.cursor = "wait";
    this.selectedCompanyName = companyName;
    this.summary = new CompanySummary();
    return this.restApi.getSecureList(companyID, companySubLevelNo, companySubLevelId).subscribe((data: any) => {
        this.getCompanySummary();
        if (!this.notificationsRetrieved) {
          this.getNotificationList();
        }
        document.body.style.cursor = "pointer";
      },
      error => {
        this.error = error;
        this.loadingAccountList = false;
        document.body.style.cursor = "pointer";
      });
  }

  getCompanySummary() {
    return this.restApi.getCompanySummary().subscribe((data: {}) => {
        this.cardHolderList = [];
        this.cardNumbers = [];
        this.associatedCards = [];
        this.loadingCompanyList = false;
        this.loadingAccountList = false;
        this.responseData = data;
        this.summary = this.responseData;
        this.cards = this.summary.Cards;
        if (this.cards === null) {
          this.cards = [];
          this.summary.Cards = [];
        }
        this.populateEmptyContactNumbersWithMobile(this.cards);
        this.userFriendlyCardMask(this.cards);
        this.createCardInfoListForSpendAnalysis(this.summary.Cards);
        this.dataStore.setCompanySummary(this.summary);
        this.cardsCombo = { card: this.cards, summary: this.summary};
        this.redirect.emit(this.cardsCombo);
      },
      error => {
        this.error = error;
        this.loadingAccountList = false;
      });
  }

  userFriendlyCardMask(cards: Card[]) {
    for (let card of cards) {
      let tempString = card.Account;
      card.MaskedCardNumber = tempString.replace(tempString.substr(4, 8), '******');
    }
    this.cards = cards;
  }

  // userFriendlyNotifications(notifications: Notification[]) {
  //
  //   for (let notification of notifications) {
  //     if ((notification.Account.length === 13 )) {
  //       let tempString = notification.Account;
  //       notification.MaskedNumber = tempString.replace(tempString.substr(4, 8), '******');
  //     }else {
  //       notification.MaskedNumber = notification.Account;
  //     }
  //   }
  //   this.notifications = notifications;
  // }

  populateEmptyContactNumbersWithMobile(cards: Card[]) {
    for (let card of cards) {
      if (!card.ContactNumber && card.MobileNumber) {
        card.ContactNumber = card.MobileNumber;
      }
    }
    this.cards = cards;
  }

  getNotificationList() {
    return this.restApi.getNotifications().subscribe((data: {}) => {
        this.responseData = data;
        if (this.responseData !== '[]') {
          this.notifications = this.responseData;
          // this.userFriendlyNotifications(this.notifications);
          this.notificationsRetrieved = true;
          this.loadingNotificationList = false;
        }
      },
      error => {
        this.error = error;
        this.loadingNotificationList = false;
      });
  }

  createCardInfoListForSpendAnalysis(cards: Card[]) {
    for (let card of cards) {
      if (card.Status === 'ACTIVE') {
        this.cardHolderList.push(card.Name);
        let maskedCard = new MaskedCard();
        maskedCard.cardHolder = card.Name;
        maskedCard.cardNo = card.Account;
        maskedCard.maskedCardNo = card.MaskedCardNumber;
        this.cardNumbers.push(maskedCard);
      }
    }
  }

  getAssociatedCardNumbers($event) {
    this.associatedCards = [];
    if ($event.source.value !== "") {
      for (let card of this.cardNumbers) {
        if (card.cardHolder === $event.source.value) {
          this.associatedCards.push(card);
        }
      }
    }
  }

  get f() {
    return this.formGroup.controls;
  }


  onFormSubmit() {
    if (this.formGroup.valid) {
      let spendAnalysisSelection = this.cards.find(obj => {
        return obj.Name === this.formGroup.value.cardHolder && obj.Account === this.formGroup.value.cardNumber;
      });
      this.selectedCardLimit = spendAnalysisSelection.CreditLimit;
      this.selectedCardAvailable = spendAnalysisSelection.AvailableBalance;
      this.barChartData = [];
      this.barChartData.push({data: [this.selectedCardLimit]}, {data: [this.selectedCardAvailable]}, {data: [0]});
    }
  }

  resetValues() {
    this.formGroup.reset();
    this.barChartData = [];
  }


}
