<mat-selection-list (selectionChange)="dtm.clickEvent('informational', 'product offering nav','corporate card dashboard | product offering | '+$event.source.selectedOptions.selected[0].getLabel()+' tab link click')">
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/overview">OVERVIEW</mat-list-option>
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/aviationCard">AVIATION CARD</mat-list-option>
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/businessCreditCards">BUSINESS CREDIT CARD</mat-list-option>
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/businessChargeCards">BUSINESS CHARGE CARD</mat-list-option>
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/forexCard" forexCard>FOREX LODGE CARD</mat-list-option>
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/garageCard">GARAGE CARD</mat-list-option>
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/procurementCard">PROCUREMENT CARD</mat-list-option>
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/travelCard">TRAVEL LODGE CARD</mat-list-option>
  <mat-list-option class="Rectangle-Copy-4 not-empty-select" [routerLinkActive]="['is-active']" routerLink="/productoffering/rollUpNonRollUp">DISTINGUISHING BETWEEN ROLLUP
    AND NON-ROLL
    UP
  </mat-list-option>
</mat-selection-list>
