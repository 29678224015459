import { AuthorizationConfigProd, GeneralEnvironmentInfo } from '../app/authorization_config';

export const environmentProd: AuthorizationConfigProd & GeneralEnvironmentInfo  = {
  production: true,
  appVersion: require('../../package.json').version,
  destEmail: 'jurgen.schatz@standardbank.co.za',
  fromEmail: 'corporate-card@standardbank.co.za',
  issuer_uri: 'https://enterprisests.standardbank.co.za',
  client_id: 'c8184c5b-afcc-45e1-acb4-53d1db3c13ca',
  redirect_uri: 'https://' + window.location.hostname.toLowerCase() + '/callback',
  response_type: 'code',
  scope: 'openid email profile',
  code_challenge: 'HsaFcD6QzAR1DBccrWf9Vm1dytXHolG1snFtSLj54OQ',
  code_challenge_method: 'S256',
};
