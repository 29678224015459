<!-- dialog-box.component.html -->

<div class="dialogBox">
  <h1 class="title" mat-dialog-title>Confirm {{action}}</h1>
    <p class="text">You are about to change the credit limit from <b>R{{currentLimit}}</b> to
      <b>R{{newAmount}}</b></p>
    <p class="text">Do you want to proceed?</p>
  <div id="buttonGroup" mat-dialog-actions>
    <button id="cancel" type="button" mat-button (click)="closeDialog()" mat-flat-button color="basic">NO</button>
    <button id="submit" type="button" mat-button  (click)="doAction()">YES</button>
  </div>
</div>
