import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators} from '@angular/forms';
import {DtmAnalyticsServiceService} from '../dtm-analytics-service.service';
import { Router } from '@angular/router';
import {RestApiService} from '../_services/rest-client-service';
import {AuthorizationService} from '../authorization.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorStateMatcher} from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { environmentProd } from 'src/environments/environment.prod';


@Component({
  selector: 'app-id',
  templateUrl: './id.component.html',
  styleUrls: ['./id.component.css']
})

export class IdComponent implements OnInit {

  constructor(private snackBar: MatSnackBar, private restApi: RestApiService, public dtm: DtmAnalyticsServiceService,
              public router: Router, public authorizationService: AuthorizationService) { }

  loading = false;
  error = '';
  errorStr = '';
  idAndCompanyRegistrationForm: FormGroup;
  redirect: string;
  redirect_url: string;
  prodHostName: string;

  ngOnInit(): void {

    // create form and controls with validators
    this.idAndCompanyRegistrationForm = new FormGroup({

      IdNumber: new FormControl('', [Validators.required,
        Validators.pattern('^[0-9]{13}$')]),
      CompanyRegistrationId: new FormControl('', [Validators.required,
        Validators.pattern('^((\\d{4})|([A-Z][A-Z]\\d{2}))(\\/)(\\d{6})(\\/)(\\d{2}$)')]),
      CompanyName: new FormControl('',[Validators.required]),
    });
    this.dtm.updatePageProperties('business:corporate card Registration');
    this.dtm.updateDataLayerForPage();
    this.prodHostName = environment.prod_url;
  }

  onSubmit(formData: any, formDirective: FormGroupDirective) {
    const IdNo = this.idAndCompanyRegistrationForm.get('IdNumber').value;
    const CompRegId = this.idAndCompanyRegistrationForm.get('CompanyRegistrationId').value;
    const CompName = this.idAndCompanyRegistrationForm.get('CompanyName').value;
    this.loading = true;
    return this.restApi.login(IdNo, CompRegId, CompName).subscribe((data: any) => {
      if (document.location.hostname === this.prodHostName) {
        this.redirect = environmentProd.redirect_uri;
      } else {
        this.redirect = environment.redirect_uri;
      }
      this.redirect_url = this.redirect.replace("/callback", "");
      if (data.status === 'No Companies') {
        sessionStorage.clear();
        this.authorizationService.signOut();
        this.router.navigateByUrl('/');
        if (document.location.hostname === this.prodHostName) {
          document.location.href = environmentProd.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environmentProd.client_id + `&TargetResource=` + this.redirect_url
        } else {
          document.location.href = environment.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environment.client_id + `&TargetResource=` + this.redirect_url
        }
        this.errorStr = 'Company Registration ' + CompRegId + ' not found';
        this.openFailureSnackBar(this.errorStr);
      } else {
        sessionStorage.clear();
        this.authorizationService.signOut();
        this.router.navigateByUrl('/');
        if (document.location.hostname === this.prodHostName) {
          document.location.href = environmentProd.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environmentProd.client_id + `&TargetResource=` + this.redirect_url
        } else {
          document.location.href = environment.issuer_uri + `/idp/startSLO.ping?AdapterId=` + environment.client_id + `&TargetResource=` + this.redirect_url
        }
        this.openSuccessSnackBar('Company successfully registered.');
      }
    },
    error => {
      // console.log('ERROR>: ' + error);
      this.error = error;
    });
  }

  openSuccessSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 6000,
      panelClass: ['green-snackbar', 'login-snackbar'],
      verticalPosition: 'top',
    });
  }

  openFailureSnackBar(message: string) {
    this.snackBar.open(message, 'Dismiss', {
      duration: 10000,
      panelClass: ['black-snackbar', 'login-snackbar'],
      verticalPosition: 'top',
    });
  }
}
