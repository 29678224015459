import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

declare var _satellite;
declare var dataLayer;

@Injectable({
  providedIn: 'root'
})
export class DtmAnalyticsServiceService {

  constructor(public router: Router) {
  }

  updatePageProperties(pageName) {
    const currentPathSplit = pageName.split(':');
    dataLayer.pageName = ' ';
    for (let i = 1; i < currentPathSplit.length; i++) {
      if (i === 1) {
        dataLayer.pageName = dataLayer.pageName + currentPathSplit[i];
      } else {
        dataLayer.pageName = dataLayer.pageName + ':' + currentPathSplit[i];
      }
    }
    dataLayer.pageCategory = currentPathSplit[0];
    dataLayer.pageSubSection1 = currentPathSplit.length > 1
      ? currentPathSplit[1] : '';
    dataLayer.pageSubSection2 = currentPathSplit.length > 2
      ? currentPathSplit[1] + ':' + currentPathSplit[2] : '';
    dataLayer.pageSubSection3 = currentPathSplit.length > 3
      ? currentPathSplit[1] + ':' + currentPathSplit[2] + ' : ' + currentPathSplit[3] : '';
    dataLayer.pageSubSection4 = currentPathSplit.length > 4
      ? currentPathSplit[1] + ':' + currentPathSplit[2] + ' : ' +
      currentPathSplit[3] + ':' + currentPathSplit[4] : '';
    dataLayer.isformSubmitted = false;
  }

  clickEvent(DATA_INTENT, DATA_SCOPE, DATA_TEXT) {
    dataLayer.linkIntent = DATA_INTENT;
    dataLayer.linkScope = DATA_SCOPE;
    dataLayer.linkName = DATA_INTENT + ' | ' + DATA_SCOPE + ' | ' + DATA_TEXT;

    _satellite.track('flexiLinkTracking');
  }

  clickEventLogout(DATA_INTENT, DATA_SCOPE, DATA_TEXT) {
    if (typeof dataLayer === 'undefined' || typeof _satellite === 'undefined') {
      this.router.navigateByUrl('/');
    } else {
      dataLayer.linkIntent = DATA_INTENT;
      dataLayer.linkScope = DATA_SCOPE;
      dataLayer.linkName = DATA_INTENT + ' | ' + DATA_SCOPE + ' | ' + DATA_TEXT;

      _satellite.track('flexiLinkTracking');
    }
  }

  updateDataLayerLogout() {
    dataLayer.userLoginSuccess = false;
    dataLayer.loginStatus = 'logged out';
    dataLayer.userID = 'No user';
    if (typeof _satellite !== 'undefined') {
      // _satellite.track('globalApplicationStart');
      _satellite.track('globalVirtualPageView');
    }
  }

  updateDataLayerForPage() {
    dataLayer.isformSubmitted = false;
    if (dataLayer.loginStatus === 'logged out') {
      dataLayer.isformSubmitted = false;
      dataLayer.userLoginSuccess = true;
      dataLayer.loginStatus = 'logged in';
    }
    dataLayer.userID = 'Authenticated user';
    if (typeof _satellite !== 'undefined') {
      _satellite.track('globalVirtualPageView');
    }
  }

  updateDataLayerForPageWithForm() {
    // tslint:disable-next-line:triple-equals
    if (dataLayer.application.applicationStart !== true) {
      dataLayer.isformSubmitted = false;
      dataLayer.userLoginSuccess = false;
      dataLayer.formIsSubmitted = false;
      dataLayer.userID = 'Authenticated user';
      dataLayer.formName = 'help log support query form';
      dataLayer.application.applicationStart = true;
      if (typeof _satellite !== 'undefined') {
        _satellite.track('globalFormStart');
      }
      dataLayer.formName = '';
      dataLayer.isformSubmitted = true;
      dataLayer.formIsSubmitted = true;
    }
  }

  updateDataLayerSubmit() {
    dataLayer.application.applicationStart = false;
    dataLayer.formStatus = 'Complete';
    dataLayer.isformSubmitted = true;
    dataLayer.formName = 'help log support query form';
    if (typeof _satellite !== 'undefined') {
      _satellite.track('globalFormComplete');
    }
    dataLayer.formName = '';
    dataLayer.formStatus = '';
  }

  sendSiteError(errorCode, request, message){
    dataLayer.siteErrorCode = errorCode + ' | ' + request + ' | ' + message
    _satellite.track('sendSiteErrorCode');
  }

}
