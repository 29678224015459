import { Component, OnInit } from '@angular/core';
import {DtmAnalyticsServiceService} from '../../dtm-analytics-service.service';

@Component({
  selector: 'app-business-credit-corporate-charge-cards',
  templateUrl: './business-credit-corporate-charge-cards.component.html',
  styleUrls: ['./business-credit-corporate-charge-cards.component.css']
})
export class BusinessCreditCorporateChargeCardsComponent implements OnInit {
  currentUser: string;

  constructor(public dtm: DtmAnalyticsServiceService) {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  ngOnInit(): void {
  }

}
