<div class="welcomeHeading">Welcome to Corporate Card</div>
<br>
<div class="welcomeMessage">
<p>Dear {{currentUser}}</p>
<p>On your profile, you have access to the following functions:</p>
<ul>
  <li>View Company Portfolio</li>
  <li>View Transaction Details</li>
  <li>Download Transaction Details</li>
  <li>View Product Offerings</li>
</ul>
</div>
