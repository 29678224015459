<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Corporate Card Solutions</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <mat-tab-group dynamicHeight class="col-9 w-100" (selectedTabChange)="dtm.clickEvent('informational', 'charge cards nav', 'corporate card dashboard | product offering | business credit and corporate charge cards | ' + $event.tab.textLabel + ' tab link click')">
      <mat-tab label="CORPORATE CHARGE CARDS">
        <div class="row pt-5">
          <img class="col-4 w-100 h-100" src='../../../assets/manWoman.png' alt="Image of man and woman">
          <div class="col-4">
            <h4 class="SubHeading">Corporate Charge Cards</h4>
            <div class="TextContent">
              <p>
                The Corporate Charge Card is a flexible, convenient payment solution for business travel and
                entertainment expenses such as flight tickets, hotels, car hire and meals.
              </p>
              <p>
                Choose between a VISA or MasterCard depending on your business needs. Minimise the administrative burden
                and risks associated with authorising cash advances and carrying large amounts of cash around.
                Cardholders can dictate unique card features such as which merchants can accept their card, when it can
                be used internationally and the ability to control the withdrawal of cash.
              </p>
              <p>
                User-friendly, web-based expense management tools, such as MasterCard Smart Data Online and Visa
                Intellilink, allow for the tracking and control of business expenditure at all times.
              </p>
              <p>
                Companies can understand their spending patterns and takeadvantage of opportunities to save with
                customisable business management reporting tools.
              </p>
            </div>
          </div>
          <div class="col-4">
            <img class="w-100 " src="../../../assets/corporateCard.png" alt="Image of travel lodge cards">
            <button mat-raised-button color="primary" hidden>APPLY NOW</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
