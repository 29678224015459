<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Aviation Card</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <mat-tab-group dynamicHeight class="col-9 w-100">
      <mat-tab label="AVIATION CARD">
        <div class="row pt-5">
          <img class="col-4 w-100 h-100" src="../../../assets/manBoardingPlane.png" alt="Image of guy boarding plane">
          <div class="col-4">
            <h4 class="SubHeading">Aviation Card</h4>
            <div class="TextContent">
              <p>
                Companies that own corporate aircrafts use the Aviation Card for operational expenses. It’s a
                cost-effective solution that allows pilots to pay for aviation-related expenses immediately.
              </p>
            </div>
          </div>
          <div class="col-4">
            <img class="w-100" src="../../../assets/aviationCard.png" alt="Image of aviation cards">
            <button mat-raised-button color="primary" hidden>APPLY NOW</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
