<app-header [(currentUser)]="currentUser"></app-header>
<div class="content">
  <h1 class="HeadingText">Product Offering: Travel Lodge Card</h1>
  <div class="row">
    <div class="h-25 col-3">
      <app-navigation></app-navigation>
    </div>
    <mat-tab-group dynamicHeight class="col-9 w-100">
      <mat-tab label="TRAVEL LODGE CARD">
        <div class="row pt-5">
          <img class="col-4 w-100 h-100" src="../../../assets/manWomanBoardingPlane.png" alt="Image of man and woman">
          <div class="col-4">
            <h4 class="SubHeading">Travel Lodge Card</h4>
            <div class="TextContent">
              <p>
                The Travel Lodge Card is designed for convenience in respect of a company’s travel arrangements, with
                prompt bookings and payments from one consolidated account.
              </p>
              <p>
                The card is held in the company’s name at their approved travel agency and covers air tickets, car
                rental, accommodation charges and any other travel-related expenses.
              </p>
              <p>
                Cardholders also receive free travel insurance cover of up to R1 million when they use the card for
                travel ticket purchases.
              </p>
            </div>
          </div>
          <div class="col-4">
            <img class="w-100" src="../../../assets/travelCard.png" alt="Image of travel lodge cards">
            <button mat-raised-button color="primary" hidden>APPLY NOW</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<footer class="footer">
  <div class="footerText">Standard Bank is a licensed financial services provider in terms of the Financial Advisory and Intermediary Services Act and a registered credit provider in terms of the National Credit Act, registration number NCRCP15</div>
</footer>
