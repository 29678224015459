import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import { CookieService } from 'ngx-cookie';
import {RestApiService} from '../_services/rest-client-service';

const LS_TOKEN_RESPONSE = 'authorization.service.token_response';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private restApi: RestApiService, private cookieService: CookieService) {
  }

  public tokenResponseJSON: any;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes('/rest/login')){
      this.tokenResponseJSON = JSON.parse(this.cookieService.get(LS_TOKEN_RESPONSE)!);
      const TOKEN_HEADER_KEY = 'X-Session-Token';
      request = request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, this.tokenResponseJSON.access_token)});
    }else {
      const TOKEN_HEADER_KEY = 'X-Session-Token';
      request = request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, this.restApi.getTokenAuth())});
      // console.log("set header ", request.url, this.restApi.getTokenAuth());
    }
    return next.handle(request);
  }
}
